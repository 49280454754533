import React, { useMemo, useState } from 'react';

import {
	Client as C,
	Service,
	useCurrentUser,
	useInjection,
} from 'src/services';

import { SelectItemsDialog } from 'src/components/selectItemsDialog';
import { QueryUserGroupCallGroupsPage_callGroups } from 'src/graphql/__generated__/QueryUserGroupCallGroupsPage';

interface Props {
	userGroupId: string;
	callGroups: QueryUserGroupCallGroupsPage_callGroups[];
	existing: string[];
	complete: () => void;
	closeDialog: () => void;
}

export const AddCallGroupsDialog = (props: Props) => {
	const client = useInjection<C.Client>(Service.ApiClient);
	const currentUser = useCurrentUser();

	const [saving, setSaving] = useState<boolean>(false);
	const [savingError, setSavingError] = useState<boolean>(false);

	const items = useMemo(() => {
		const exclude = new Set<string>(props.existing);

		return props.callGroups
			.filter(x => x.name.trim().length !== 0 && !exclude.has(x.id))
			.map(x => ({
				id: x.id,
				name: (currentUser!.identity.type === C.IdentityType.SuperUser || currentUser!.identity.type === C.IdentityType.Dealer) ? `${x.name} (${x.client.name})` : x.name,
			}))
			.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));
	}, [props.callGroups, props.existing]);

	const addCallGroups = async (callGroupIds: string[]) => {
		setSaving(true);

		try {
			const request = new C.UpdateUserGroupCallGroupsByUserGroupIdRequest({
				addCallGroupIds: callGroupIds,
			});

			await client.updateUserGroupCallGroupsByUserGroupId(props.userGroupId, request);

			props.complete();
			props.closeDialog();
		} catch (err) {
			setSavingError(true);
			setSaving(false);
		}
	};

	return <SelectItemsDialog
		title="Select call groups to add"
		complete={addCallGroups}
		close={props.closeDialog}
		saving={saving}
		savingError={savingError}
		items={items}
	/>;
};
