import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationUpdateGenericDevice($input: UpdateGenericDeviceInput!) {
		updateGenericDevice(input: $input) {
			genericDevice {
				id
			}
		}
	}
`;
