import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation MutationAddGenericDevice($input: AddGenericDeviceInput!) {
		addGenericDevice(input: $input) {
			genericDevice {
				id
				asset {
					id
				}
			}
		}
	}
`;
