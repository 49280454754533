import { IOption } from 'src/util';

export const dealerOptions: IOption<string>[] = [
	{
		value: 'ChatterPtt',
		label: 'ChatterPTT'
	},
	{
		value: 'HyteraTier2',
		label: 'Hytera Tier 2',
	},
	{
		value: 'Generic',
		label: 'Generic',
	},
	{
		value: 'TaitTier2',
		label: 'Tait Tier 2',
	},
	{
		value: 'Teltonika',
		label: 'Teltonika',
	}
];

export const superUserOptions: IOption<string>[] = [
	{
		value: 'ChatterPtt',
		label: 'ChatterPTT'
	},
	{
		value: 'HyteraTier2',
		label: 'Hytera Tier 2',
	},
	{
		value: 'Generic',
		label: 'Generic',
	},
	{
		value: 'TaitTier2',
		label: 'Tait Tier 2',
	},
	{
		value: 'TaitTier3',
		label: 'Tait Tier 3',
	},
	{
		value: 'Teltonika',
		label: 'Teltonika',
	}
];
