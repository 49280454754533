import React from 'react';
import { observer } from 'mobx-react';
import { Field, FormikProps } from 'formik';

import { FormikCheckbox, FormikSelect, FormikTextField } from 'src/components';
import { NetworkSelector } from 'src/components/form';
import { IDeviceNetwork } from './manageDeviceComponent';
import { DeviceType } from 'src/../__generated__/globalTypes';

interface IGenericDeviceFormValues {
	dealerId: string | null;
	networkId: string | null;
	networkDeviceId: string;
	gpsEnabled: boolean;
	bleEnabled: boolean;
	callRecordingEnabled: boolean;
	manualPollingEnabled: boolean;
}

interface IProps<T> {
	form: FormikProps<T>;
	hasDealerSelection: boolean;
	bluetoothBeaconSets?: {
		id: string;
		name: string;
	}[];
	dealers?: {
		id: string;
		name: string;
	}[];
	networks?: IDeviceNetwork[];
}

export const ManageGenericDeviceComponent = observer(<T extends IGenericDeviceFormValues>(props: IProps<T>) => {
	const network = !!props.form.values.networkId && props.networks?.find(x => x.id === props.form.values.networkId);

	return <>
		<NetworkSelector
			form={props.form}
			deviceType={DeviceType.GENERIC}
			dealers={props.dealers}
			networks={props.networks}
		/>

		<Field
			name="networkDeviceId"
			label="Network Device ID"
			type="text"
			component={FormikTextField}
			helperText="The unique identifier for this device on the network."
		/>

		<Field
			name="gpsEnabled"
			label="Use locations received via GPS"
			type="checkbox"
			component={FormikCheckbox}
		/>

		<Field
			name="bleEnabled"
			label="Use locations received via BLE"
			type="checkbox"
			component={FormikCheckbox}
		/>

		<FormikSelect
			name="bluetoothBeaconSetIds"
			label="Bluetooth Beacon Sets"
			form={props.form}
			disabled={!props.form.values.bleEnabled || (props.hasDealerSelection && !props.form.values.dealerId)}
			options={props.bluetoothBeaconSets || []}
			getOptionValue={x => x.id}
			getOptionLabel={x => x.name}
			clearable
			multi
			disableCloseOnSelect
		/>

		<Field
			name="manualPollingEnabled"
			label="Manual polling enabled"
			type="checkbox"
			component={FormikCheckbox}
		/>
	</>;
});
