import React, { useState } from 'react';
import { observer } from 'mobx-react';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import { MaterialTableProps } from '@material-table/core';
import useAsyncEffect from 'use-async-effect';

import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { ActivateDevicesDialog, AssignDeviceToAssetDialog, Button, DeactivateDevicesDialog, DeleteDevicesDialog, ErrorMessagePage, FixedWidthPage, LoadingMessagePage, MaterialTable, PopoverMenu, PopoverMenuItem, UnassignAssetDevice, UnassignDeviceFromAssetDialog } from 'src/components';
import { getPrettyName } from 'src/util/prettyNames';

import { QueryDevicesList_devices, useQueryDevicesList } from 'src/graphql/__generated__/queries/queryDevicesList';
import { Client as C, EnableFleetConfigurationService, Service, useCurrentUser, useInjection, usePermissions } from 'src/services';
import { DeviceActivationState, DeviceType } from 'src/../__generated__/globalTypes';
import { getDeviceTypeOptions } from './deviceTypeOptions';
import { Tooltip } from '@material-ui/core';
import { DeviceConfigurationUpdateDialog } from 'src/app/deviceConfigurations/deviceConfigurationUpdateDialog';

interface IDeviceListRow {
	id: string;
	name: string;
	assetName?: string;
	deviceType: DeviceType;
	serialNumber?: string;
	dealer?: string;
	client?: string;
	activationState?: DeviceActivationState;
	imei?: string;
	networkDeviceId?: string;
	vehiclePlate?: string;
	device: QueryDevicesList_devices;
	phoneNumber?: string;
}

export const DevicesList = observer(() => {
	const currentUser = useCurrentUser()!;
	const permissions = usePermissions()!;
	const identityType = currentUser.identity.type;
	const enableFleetConfigurationService = useInjection<EnableFleetConfigurationService>(Service.EnableFleetConfiguration);

	const [activateDeviceIds, setActivateDeviceIds] = useState<string[] | null>(null);
	const [deactivateDeviceIds, setDeactivateDeviceIds] = useState<string[] | null>(null);
	const [deleteDeviceIds, setDeleteDeviceIds] = useState<string[] | null>(null);
	const [assignDevice, setAssignDevice] = useState<QueryDevicesList_devices | null>(null);
	const [unassignAssetDevice, setUnassignAssetDevice] = useState<UnassignAssetDevice | null>(null);
	const [configurationUpdateDevices, setConfigurationUpdateDevices] = useState<QueryDevicesList_devices[] | null>(null);

	const [enableFleetSites, setEnableFleetSites] = React.useState<string[] | null>(null);
	const [loading, setLoading] = React.useState(true);

	useAsyncEffect(async () => {
		if (enableFleetSites === null) {
			setEnableFleetSites(await enableFleetConfigurationService.getEnableFleetSitesWithPermissions());
			setLoading(false);
		}
	}, []);

	const query = useQueryDevicesList({
		variables: {
			includeDealer: identityType === C.IdentityType.SuperUser,
			includeClient: identityType === C.IdentityType.SuperUser || identityType === C.IdentityType.Dealer,
			includeNetwork: identityType === C.IdentityType.SuperUser || identityType === C.IdentityType.Dealer,
		},
	});

	if (loading || query.loading)
		return <LoadingMessagePage />;

	if (query.error || !query.data?.devices)
		return <ErrorMessagePage />;

	const canActivateDevices = permissions.activateDevices || false;
	const canDeactivateDevices = permissions.deactivateDevices || false;
	const canDeleteDevices = permissions.deleteDevices || false;
	const canApplyDeviceConfigurations = permissions.applyDeviceConfigurations || false;

	const deviceRows = query.data.devices
		.map<IDeviceListRow>(device => ({
			id: device.id,
			name: device.name,
			assetName: device.asset?.name,
			deviceType: device.deviceType,
			serialNumber: device.serialNumber || undefined,
			dealer: device.dealer?.name,
			client: device.client?.name,
			activationState: device.activationState || undefined,
			imei: (device as any).imei || undefined,
			networkDeviceId: (device as any).radioId || (device as any).networkDeviceId || undefined,
			vehiclePlate: (device as any).vehiclePlate || undefined,
			device,
			phoneNumber: (device as any).phoneNumber || undefined
		}))
		.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

	const deviceTypes: { [key: string]: string } = {};
	const activationStates: { [key: string]: string } = {};
	let hasNetworkDeviceIds = false;
	let hasImeis = false;
	let hasVehiclePlates = false;
	let hasPhoneNumbers = false;
	let hasSites = false;

	for (const deviceRow of deviceRows) {
		if (!deviceTypes[deviceRow.deviceType])
			deviceTypes[deviceRow.deviceType] = getPrettyName(deviceRow.deviceType);

		if (deviceRow.activationState && !activationStates[deviceRow.activationState])
			activationStates[deviceRow.activationState] = getPrettyName(deviceRow.activationState);

		if (!hasNetworkDeviceIds && deviceRow.networkDeviceId)
			hasNetworkDeviceIds = true;

		if (!hasImeis && deviceRow.vehiclePlate)
			hasImeis = true;

		if (!hasVehiclePlates && deviceRow.vehiclePlate)
			hasVehiclePlates = true;

		if (!hasPhoneNumbers && deviceRow.phoneNumber)
			hasPhoneNumbers = true;

		if (!hasSites && (deviceRow.device as any).network)
			hasSites = true;
	}

	const renderAssetRowDropdown = (deviceRow: IDeviceListRow) => {
		const options: JSX.Element[] = [
			<ListItem style={{ fontWeight: 500 }}>Device: {deviceRow.name}</ListItem>,
			<PopoverMenuItem
				key="edit"
				text="Edit"
				href={`/app/devices/${deviceRow.device.id}/edit`}
				title="Edit device"
			/>,
		];

		const networkId = (deviceRow.device as any).network?.id;
		const enableFleetConfigurable = deviceRow.device.deviceType === DeviceType.TAIT_RADIO && !!deviceRow.serialNumber &&
			networkId && enableFleetSites && enableFleetSites.indexOf(networkId) > -1;

		if (enableFleetConfigurable) {
			options.push(<PopoverMenuItem
				key="configure"
				text="Edit EnableFleet Settings"
				title="Edit EnableFleet settings"
				href={`/app/enablefleet/assets/${deviceRow.id}/configure`}
			/>);
		}

		if (canApplyDeviceConfigurations && deviceRow.device.deviceType === DeviceType.ATRACK) {
			const hasModelType = (deviceRow.device as any).atrackModelType != null;
			options.push(<Tooltip title={!hasModelType ? 'The selected device does not have a model type specified.' : ''} arrow>
				<div>
					<PopoverMenuItem
						key="update-configuration"
						text="Update configuration"
						title="Update configuration"
						onClick={() => setConfigurationUpdateDevices([deviceRow.device])}
						disabled={!hasModelType}
					/>
				</div>
			</Tooltip>);
		}

		if (canActivateDevices && deviceRow.activationState !== DeviceActivationState.ACTIVATED) {
			options.push(<PopoverMenuItem
				key="activate"
				text="Activate"
				title="Activate device"
				onClick={() => setActivateDeviceIds([deviceRow.device.id])}
			/>);
		}

		if (canDeactivateDevices && deviceRow.activationState === DeviceActivationState.ACTIVATED) {
			options.push(<PopoverMenuItem
				key="deactivate"
				text="Deactivate"
				title="Deactivate device"
				onClick={() => setDeactivateDeviceIds([deviceRow.device.id])}
			/>);
		}

		if (canDeleteDevices) {
			options.push(<PopoverMenuItem
				key="delete"
				text="Delete"
				title="Delete device"
				onClick={() => setDeleteDeviceIds([deviceRow.device.id])}
				disabled={!!deviceRow.device.asset}
			/>);
		}

		if (deviceRow.device.asset) {
			options.push(<Divider style={{ margin: '10px 0' }} />);
			options.push(<ListItem style={{ fontWeight: 500 }}>Asset: {deviceRow.device.asset.name}</ListItem>);

			options.push(<PopoverMenuItem
				key="edit-asset"
				text="Edit"
				href={`/app/assets/${deviceRow.device.asset.id}/edit`}
				title="Edit assigned asset"
			/>);

			if (deviceRow.deviceType === DeviceType.TELTONIKA || deviceRow.deviceType === DeviceType.DIGITAL_MATTER) {
				options.push(<PopoverMenuItem
					key="configure-io"
					href={`/app/assets/${deviceRow.device.asset.id}/io-configurations/list`}
					text="Configure IO"
				/>);
			}

			options.push(<PopoverMenuItem
				key="unassign-asset"
				text="Unassign"
				title="Unassign"
				onClick={() => setUnassignAssetDevice({ asset: deviceRow.device.asset!, device: deviceRow.device })}
			/>);
		} else {
			options.push(<Divider style={{ margin: '10px 0' }} />);

			options.push(<PopoverMenuItem
				key="assign-asset"
				text="Assign to asset"
				onClick={() => setAssignDevice(deviceRow.device)}
				title="Assign to asset"
			/>);
		}

		return options;
	};

	const getModelType = (row: IDeviceListRow) => {
		const device = row.device as any;
		return device.atrackModelType || device.teltonikaModelType;
	};

	const renderEditMultipleDevicesMenu = (rows: IDeviceListRow[]) => {
		const canActivateAllSelected = rows.every(x => x.device.activationState !== DeviceActivationState.ACTIVATED);
		const canDeactivateAllSelected = rows.every(x => x.device.activationState === DeviceActivationState.ACTIVATED);

		const differentDeviceTypes = rows.some(x => x.device.deviceType !== rows[0].device.deviceType);
		const differentModelTypes = rows.some(x => {
			const modelType = getModelType(x);
			return !modelType || modelType !== getModelType(rows[0]);
		});
		const differentDeviceTypeOrModelTypeSelected = differentDeviceTypes || differentModelTypes;

		const options: JSX.Element[] = [];

		if (canActivateDevices) {
			options.push(<PopoverMenuItem
				key="activate"
				text="Activate"
				title={canActivateAllSelected ? 'Activate selected devices' : 'Device selection contains devices which cannot be activated.'}
				onClick={() => setActivateDeviceIds(rows.map(x => x.id))}
				disabled={!canActivateAllSelected}
			/>);
		}

		if (canDeactivateDevices) {
			options.push(<PopoverMenuItem
				key="deactivate"
				text="Deactivate"
				title={canDeactivateAllSelected ? 'Deactivate selected devices' : 'Device selection contains devices which cannot be deactivated.'}
				onClick={() => setDeactivateDeviceIds(rows.map(x => x.id))}
				disabled={!canDeactivateAllSelected}
				/>);
			}

		if (canDeleteDevices) {
			options.push(<PopoverMenuItem
				key="delete"
				text="Delete"
				title="Delete selected devices"
				onClick={() => setDeleteDeviceIds(rows.map(x => x.id))}
			/>);
		}

		if (canApplyDeviceConfigurations) {
			options.push(<Tooltip title={differentDeviceTypeOrModelTypeSelected ? 'Model type(s) are missing, or the device/model types of the selected devices do not match.' : ''} arrow>
				<div>
					<PopoverMenuItem
						key="update-configurations"
						text="Update configurations"
						title="Update configurations"
						onClick={() => setConfigurationUpdateDevices(rows.map(x => x.device))}
						disabled={differentDeviceTypeOrModelTypeSelected}
					/>
				</div>
			</Tooltip>);
		}

		return options;
	};

	const renderTableActions = (props: MaterialTableProps<IDeviceListRow>) => {
		if (!props.data || props.data.length === 0)
			return null;

		return <PopoverMenu
			renderOptions={() => renderEditMultipleDevicesMenu(props.data as IDeviceListRow[])}
		>
			<Button
				text="Edit Assets"
				endIcon={<ArrowDropDownIcon />}
				color="primary"
				variant="contained"
			/>
		</PopoverMenu>;
	};

	const addDeviceTypeOptions = getDeviceTypeOptions(currentUser.identity);

	const renderHeadingActions = () => {
		return <>
			<Button href="/app/device-configurations/list" text="Device Configurations" variant="outlined" color="primary"/>
			{addDeviceTypeOptions.length === 0 ? null : <Button href="/app/devices/add" text="Add" startIcon={<AddIcon />} variant="outlined" color="primary"/>}
		</>;
	};

	return <FixedWidthPage
		headingText="Devices"
		headingActions={renderHeadingActions()}
		noContentBackground
	>
		<MaterialTable
			tableName="devices-list"
			columns={[
				{
					title: 'Name',
					field: 'name',
					grouping: false,
				},
				{
					title: 'Asset Name',
					field: 'assetName',
					grouping: false,
				},
				{
					title: 'Device Type',
					field: 'deviceType',
					lookup: deviceTypes,
				},
				{
					title: 'Serial Number',
					field: 'serialNumber',
				},
				{
					title: 'IMEI',
					field: 'imei',
					hidden: !hasImeis,
					hiddenByColumnsButton: !hasImeis,
				},
				{
					title: 'Radio/Network Device ID',
					field: 'networkDeviceId',
					hidden: !hasNetworkDeviceIds,
					hiddenByColumnsButton: !hasNetworkDeviceIds,
				},
				{
					title: 'Phone Number',
					field: 'phoneNumber',
					hidden: !hasPhoneNumbers,
					hiddenByColumnsButton: !hasPhoneNumbers,
				},
				{
					title: 'Vehicle Plate',
					field: 'vehiclePlate',
					hidden: !hasVehiclePlates,
					hiddenByColumnsButton: !hasVehiclePlates,
				},
				{
					title: 'Site Name',
					field: 'device.network.name',
					hidden: !hasSites,
					hiddenByColumnsButton: !hasSites,
				},
				{
					title: 'Dealer',
					field: 'dealer',
					hidden: identityType !== C.IdentityType.SuperUser,
					hiddenByColumnsButton: identityType !== C.IdentityType.SuperUser,
				},
				{
					title: 'Client',
					field: 'client',
					hiddenByColumnsButton: identityType !== C.IdentityType.SuperUser && identityType !== C.IdentityType.Dealer,
					hidden: identityType !== C.IdentityType.SuperUser && identityType !== C.IdentityType.Dealer,
				},
				{
					title: 'Activation State',
					field: 'activationState',
					lookup: activationStates,
					hidden: true,
				},
				{
					title: 'Options',
					field: 'options',
					grouping: false,
					filtering: false,
					sorting: false,
					headerStyle: {
						textAlign: 'right',
					},
					cellStyle: {
						textAlign: 'right',
					},
					render: rowData => <PopoverMenu
						renderOptions={() => renderAssetRowDropdown(rowData)}
					/>
				},
			]}
			data={deviceRows}
			options={{
				selection: canActivateDevices || canDeactivateDevices || canDeleteDevices || canApplyDeviceConfigurations,
				rowStyle: (row: IDeviceListRow) => ({
					backgroundColor: row.device.activationState === DeviceActivationState.ACTIVATED && row.device.asset ? undefined : '#f9f9f9',
					color: row.device.activationState === DeviceActivationState.ACTIVATED && row.device.asset ? undefined : '#757373',
				}),
			}}
			components={{
				Actions: renderTableActions,
			}}
			localization={{
				toolbar: {
					nRowsSelected: '{0} device(s) selected',
				},
			}}
		/>

		{activateDeviceIds && <ActivateDevicesDialog
			deviceIds={activateDeviceIds}
			close={() => setActivateDeviceIds(null)}
			onSuccess={query.refetch}
		/>}

		{deactivateDeviceIds && <DeactivateDevicesDialog
			deviceIds={deactivateDeviceIds}
			close={() => setDeactivateDeviceIds(null)}
			onSuccess={query.refetch}
		/>}

		{deleteDeviceIds && <DeleteDevicesDialog
			deviceIds={deleteDeviceIds}
			close={() => setDeleteDeviceIds(null)}
			onSuccess={query.refetch}
		/>}

		{assignDevice && <AssignDeviceToAssetDialog
			device={assignDevice}
			close={() => setAssignDevice(null)}
			onSuccess={query.refetch}
		/>}

		{unassignAssetDevice && <UnassignDeviceFromAssetDialog
			assetDevice={unassignAssetDevice}
			close={() => setUnassignAssetDevice(null)}
			onSuccess={query.refetch}
		/>}

		{configurationUpdateDevices && <DeviceConfigurationUpdateDialog
			allDevices={deviceRows.map(x => x.device)}
			devices={configurationUpdateDevices}
			dialogCloseCallback={() => setConfigurationUpdateDevices(null)}
			onSuccess={query.refetch}

		/>}
	</FixedWidthPage>;
});
