import { ApolloClient, InMemoryCache } from '@apollo/client';
import Checkbox from '@material-ui/core/Checkbox';
import React, { useState } from 'react';
import { match } from 'react-router-dom';
import { Button, ThingLoader } from 'src/components';

import { executeQueryUserGroupCallGroupsPage, QueryUserGroupCallGroupsPage_callGroups } from 'src/graphql/__generated__/queries/queryUserGroupCallGroupsPage';

import {
	Client as C,
	Service,
	ToasterService,
	useCurrentUser,
	useInjection,
} from 'src/services';

import { AddCallGroupsDialog } from './addCallGroupsDialog';
import { ManageUserGroup } from './manageUserGroup';

interface Props {
	match: match<{ id: string }>;
}

interface IPageData {
	userGroup: C.IUserGroupDto;
	userGroupCallGroupIds: string[];
	allCallGroups: QueryUserGroupCallGroupsPage_callGroups[];
}

export const UserGroupCallGroups = (props: Props) => {
	const apolloClient = useInjection<ApolloClient<InMemoryCache>>(Service.ApolloClient);
	const client = useInjection<C.Client>(Service.ApiClient);
	const toasterService = useInjection<ToasterService>(Service.Toaster);
	const currentUser = useCurrentUser();

	const userGroupId = props.match.params.id;
	if (!userGroupId)
		throw 'Missing user group ID.';
	
	const [selectedCallGroups, setSelectedCallGroups] = useState<Set<string>>(new Set<string>());
	const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
	const [saving, setSaving] = useState<boolean>(false);
	
	const load = async (userGroupId: string): Promise<IPageData> => {
		const userGroup = await client.getUserGroupById(userGroupId);
		const userGroupCallGroupIds = await client.getUserGroupCallGroupsByUserGroupId(userGroupId);
		
		const query = await executeQueryUserGroupCallGroupsPage(apolloClient);
		if (query.error || !query.data!.callGroups)
			throw 'Failed to load call groups.';

		return {
			userGroup,
			userGroupCallGroupIds,
			allCallGroups: query.data.callGroups,
		};
	};

	const addUserGroupsComplete = (reload: Function) => {
		setAddDialogOpen(false);
		reload();
	};

	const toggleCallGroup = (callGroupId: string) => {
		const newSelectedCallGroups = new Set(selectedCallGroups);
		if (newSelectedCallGroups.has(callGroupId))
			newSelectedCallGroups.delete(callGroupId);
		else
			newSelectedCallGroups.add(callGroupId);

		setSelectedCallGroups(newSelectedCallGroups);
	};

	const removeCallGroups = async (reload: Function) => {
		if (!confirm('Are you sure you want to remove the selected call group(s) from this user group?'))
			return;

		setSaving(true);

		try {
			const request = new C.UpdateUserGroupCallGroupsByUserGroupIdRequest({
				removeCallGroupIds: Array.from(selectedCallGroups),
			});

			await client.updateUserGroupCallGroupsByUserGroupId(userGroupId, request);

			reload();
			setSelectedCallGroups(new Set<string>());
		} catch (err) {
			toasterService.handleWithToast(err, 'Failed to remove call groups from user group.');
		}

		setSaving(false);
	};

	return <ThingLoader
		id={userGroupId}
		load={load}
		render={(pageData: IPageData, reload: Function) => {
			const currentCallGroups = pageData.userGroupCallGroupIds
				.map(x => pageData.allCallGroups.find(y => y.id === x)!)
				.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

			return <ManageUserGroup
				userGroup={pageData.userGroup}
			>
				<div className="user-group-call-groups">
					<p>
						Users have access to call recordings associated with all call groups belonging to the same client as the user.<br />
						Add additional call groups below to allow users to access call recordings associated with call groups from <strong>other clients</strong>.
					</p>

					<div className="actions">
						<Button
							text="Remove"
							variant="outlined"
							disabled={selectedCallGroups.size === 0 || saving}
							loading={saving}
							onClick={() => removeCallGroups(reload)}
						/>

						<Button
							text="Add"
							color="primary"
							variant="contained"
							onClick={() => setAddDialogOpen(true)}
						/>
					</div>

					<table className="card-table">
						<tbody>
							{currentCallGroups.map(x => <tr key={x.id} className="content-box">
								<td>
									<Checkbox
										checked={selectedCallGroups.has(x.id)}
										onClick={() => toggleCallGroup(x.id)}
									/>
								</td>
								<td>{currentUser!.identity.client?.clientId === x.client.id ? x.name : x.name + ` (${x.client.name})`}</td>
							</tr>)}
						</tbody>
					</table>

					{addDialogOpen && <AddCallGroupsDialog
						userGroupId={pageData.userGroup.userGroupId}
						callGroups={pageData.allCallGroups}
						existing={pageData.userGroupCallGroupIds}
						complete={() => addUserGroupsComplete(reload)}
						closeDialog={() => setAddDialogOpen(false)}
					/>}
				</div>
			</ManageUserGroup>;
		}}
	/>;
};
