import { AssetServiceReminderHistoryActionType, AssetServiceReminderState, AssetTelematicsType, BluetoothBeaconType, CallType, DeviceActivationState, AssetBillingType, DeviceIoAction, DeviceIoName, DeviceType, EmergencyType, MapMarkerShape } from 'src/../__generated__/globalTypes';
import { AddressBookType } from 'src/services/clientFromNSwag';

const prettyNameMap = new Map<AddressBookType | AssetServiceReminderHistoryActionType | AssetServiceReminderState | AssetTelematicsType | BluetoothBeaconType | CallType |  DeviceActivationState | AssetBillingType | DeviceIoAction | DeviceIoName | DeviceType | EmergencyType | MapMarkerShape, string>();

prettyNameMap.set(AssetServiceReminderHistoryActionType.RESOLVE, 'Resolve');
prettyNameMap.set(AssetServiceReminderHistoryActionType.ADD, 'Add');
prettyNameMap.set(AssetServiceReminderHistoryActionType.UPDATE, 'Update');
prettyNameMap.set(AssetServiceReminderHistoryActionType.DELETE, 'Delete');

prettyNameMap.set(AssetServiceReminderState.NOT_EXPIRED, 'Not Expired');
prettyNameMap.set(AssetServiceReminderState.WITHIN_REMINDER_PERIOD, 'Within Reminder Period');
prettyNameMap.set(AssetServiceReminderState.EXPIRED, 'Expired');

prettyNameMap.set(AssetTelematicsType.ODOMETER, 'Odometer');
prettyNameMap.set(AssetTelematicsType.ENGINE_TIME, 'Engine Time');

prettyNameMap.set(BluetoothBeaconType.BUILDING_ENTRY_EXIT, 'Building Entry/Exit');
prettyNameMap.set(BluetoothBeaconType.ENTRY_EXIT, 'Entry/Exit');
prettyNameMap.set(BluetoothBeaconType.PRIORITY, 'Priority');
prettyNameMap.set(BluetoothBeaconType.STANDARD, 'Standard');

prettyNameMap.set(DeviceActivationState.ACTIVATED, 'Activated');
prettyNameMap.set(DeviceActivationState.DEACTIVATED, 'Deactivated');
prettyNameMap.set(DeviceActivationState.UNACTIVATED, 'Unactivated');

prettyNameMap.set(AssetBillingType.BASIC, 'Basic');
prettyNameMap.set(AssetBillingType.DEMO, 'Demo');
prettyNameMap.set(AssetBillingType.STANDARD, 'Standard');
prettyNameMap.set(AssetBillingType.CONFIGURATION_ONLY, 'Configuration Only');
prettyNameMap.set(AssetBillingType.DEVELOPMENT, 'Development');
prettyNameMap.set(AssetBillingType.ENTERPRISE_1, 'Enterprise 1');
prettyNameMap.set(AssetBillingType.ENTERPRISE_2, 'Enterprise 2');
prettyNameMap.set(AssetBillingType.ENTERPRISE_3, 'Enterprise 3');
prettyNameMap.set(AssetBillingType.NON_BILLABLE, 'Non Billable');
prettyNameMap.set(AssetBillingType.PREMIUM, 'Premium');
prettyNameMap.set(AssetBillingType.TRIAL, 'Trial');

prettyNameMap.set(DeviceIoAction.TRIGGER_EMERGENCY, 'Trigger Emergency');
prettyNameMap.set(DeviceIoAction.TRIGGER_COUNTER, 'Trigger Counter');
prettyNameMap.set(DeviceIoAction.TRIGGER_TIMER, 'Trigger Timer');
prettyNameMap.set(DeviceIoAction.TRIGGER_PRIORITY_ALERT, 'Trigger Priority Alert');
prettyNameMap.set(DeviceIoAction.KEY_ON, 'Key On');
prettyNameMap.set(DeviceIoAction.KEY_OFF, 'Key Off');
prettyNameMap.set(DeviceIoAction.ASSOCIATE_UNTIL_KEY_OFF, 'Associate Until Key Off');
prettyNameMap.set(DeviceIoAction.ASSOCIATE_UNTIL_REMOVED, 'Associate Until Removed');

prettyNameMap.set(DeviceIoName.DIN1, 'Digital Input 1');
prettyNameMap.set(DeviceIoName.DIN2, 'Digital Input 2');
prettyNameMap.set(DeviceIoName.DIN3, 'Digital Input 3');
prettyNameMap.set(DeviceIoName.DIN4, 'Digital Input 4');
prettyNameMap.set(DeviceIoName.IBUTTON, 'iButton');

prettyNameMap.set(DeviceType.ATRACK, 'ATrack');
prettyNameMap.set(DeviceType.CAMPER_VAN, 'Camper Van');
prettyNameMap.set(DeviceType.DIGITAL_MATTER, 'Digital Matter');
prettyNameMap.set(DeviceType.EROAD, 'EROAD');
prettyNameMap.set(DeviceType.HYTERA_RADIO, 'Hytera Radio');
prettyNameMap.set(DeviceType.GENERIC, 'Generic');
prettyNameMap.set(DeviceType.MOBILE_PHONE, 'Mobile Phone');
prettyNameMap.set(DeviceType.TAIT_RADIO, 'Tait Radio');
prettyNameMap.set(DeviceType.TELTONIKA, 'Teltonika');

prettyNameMap.set(EmergencyType.EMERGENCY, 'Emergency');
prettyNameMap.set(EmergencyType.PRIORITY_ALERT, 'Priority Alert');

prettyNameMap.set(MapMarkerShape.CIRCLE, 'Circle');
prettyNameMap.set(MapMarkerShape.SQUARE, 'Square');

prettyNameMap.set(CallType.AD_HOC_GROUP_VOICE, 'Ad hoc group call');
prettyNameMap.set(CallType.GROUP_VOICE, 'Group voice call');
prettyNameMap.set(CallType.INDIVIDUAL_VOICE, 'Individual group voice call');

prettyNameMap.set(AddressBookType.Attachment, 'Attachment');
prettyNameMap.set(AddressBookType.User, 'Driver');

export function getPrettyName(input: AddressBookType | AssetServiceReminderHistoryActionType | AssetServiceReminderState | AssetTelematicsType | BluetoothBeaconType | CallType | DeviceIoAction | DeviceIoName | DeviceActivationState | AssetBillingType | DeviceType | EmergencyType | MapMarkerShape ) {
	return prettyNameMap.get(input) || input;
}
