import { gql } from '@apollo/client';

export const MUTATION = gql`
	mutation mutationUpdateDevicesIoConfigurations($input: UpdateDevicesIoConfigurationsInput!) {
		updateDevicesIoConfigurations(input: $input) {
			devices {
				id
				deviceType
				name
				ioConfigurations {
					id
					name
					isEnabled
					ioName
					onAction
					offAction
				}
				... on ATrackDevice {
					id
				}
				... on CamperVanDevice {
					id
				}
				... on DigitalMatterDevice {
					id
					digitalMatterModelType: modelType
				}
				... on EroadDevice {
					id
				}
				... on HyteraRadioDevice {
					id
				}
				... on GenericDevice {
					id
				}
				... on MobilePhoneDevice {
					id
				}
				... on TaitRadioDevice {
					radioId
					id
				}
				... on TeltonikaDevice {
					id
					teltonikaModelType: modelType
				}
			}
		}
	}
`;
