/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ATrackDeviceModelType {
  AK1 = "AK1",
  AK7 = "AK7",
  AK7V = "AK7V",
  AL7 = "AL7",
}

export enum AssetBillingType {
  BASIC = "BASIC",
  CONFIGURATION_ONLY = "CONFIGURATION_ONLY",
  DEMO = "DEMO",
  DEVELOPMENT = "DEVELOPMENT",
  ENTERPRISE_1 = "ENTERPRISE_1",
  ENTERPRISE_2 = "ENTERPRISE_2",
  ENTERPRISE_3 = "ENTERPRISE_3",
  NON_BILLABLE = "NON_BILLABLE",
  PREMIUM = "PREMIUM",
  STANDARD = "STANDARD",
  TRIAL = "TRIAL",
}

export enum AssetServiceReminderHistoryActionType {
  ADD = "ADD",
  DELETE = "DELETE",
  RESOLVE = "RESOLVE",
  UPDATE = "UPDATE",
}

export enum AssetServiceReminderState {
  EXPIRED = "EXPIRED",
  NOT_EXPIRED = "NOT_EXPIRED",
  WITHIN_REMINDER_PERIOD = "WITHIN_REMINDER_PERIOD",
}

export enum AssetTelematicsType {
  ENGINE_TIME = "ENGINE_TIME",
  ODOMETER = "ODOMETER",
}

export enum BluetoothBeaconType {
  BUILDING_ENTRY_EXIT = "BUILDING_ENTRY_EXIT",
  ENTRY_EXIT = "ENTRY_EXIT",
  PRIORITY = "PRIORITY",
  STANDARD = "STANDARD",
}

export enum CallType {
  AD_HOC_GROUP_VOICE = "AD_HOC_GROUP_VOICE",
  GROUP_VOICE = "GROUP_VOICE",
  INDIVIDUAL_VOICE = "INDIVIDUAL_VOICE",
}

export enum DealerType {
  CONFIGURATION_ONLY = "CONFIGURATION_ONLY",
  STANDARD = "STANDARD",
}

export enum DeviceActivationState {
  ACTIVATED = "ACTIVATED",
  DEACTIVATED = "DEACTIVATED",
  UNACTIVATED = "UNACTIVATED",
}

export enum DeviceIoAction {
  ASSOCIATE_UNTIL_KEY_OFF = "ASSOCIATE_UNTIL_KEY_OFF",
  ASSOCIATE_UNTIL_REMOVED = "ASSOCIATE_UNTIL_REMOVED",
  KEY_OFF = "KEY_OFF",
  KEY_ON = "KEY_ON",
  TRIGGER_COUNTER = "TRIGGER_COUNTER",
  TRIGGER_EMERGENCY = "TRIGGER_EMERGENCY",
  TRIGGER_PRIORITY_ALERT = "TRIGGER_PRIORITY_ALERT",
  TRIGGER_TIMER = "TRIGGER_TIMER",
}

export enum DeviceIoName {
  DIN1 = "DIN1",
  DIN2 = "DIN2",
  DIN3 = "DIN3",
  DIN4 = "DIN4",
  IBUTTON = "IBUTTON",
}

export enum DeviceType {
  ATRACK = "ATRACK",
  CAMPER_VAN = "CAMPER_VAN",
  DIGITAL_MATTER = "DIGITAL_MATTER",
  EROAD = "EROAD",
  GENERIC = "GENERIC",
  HYTERA_RADIO = "HYTERA_RADIO",
  MOBILE_PHONE = "MOBILE_PHONE",
  TAIT_RADIO = "TAIT_RADIO",
  TELTONIKA = "TELTONIKA",
}

export enum DigitalMatterDeviceModelType {
  Bolt2 = "Bolt2",
  Dart3 = "Dart3",
  G120 = "G120",
  G70 = "G70",
  Oyster3 = "Oyster3",
  Remora3 = "Remora3",
  Yabby3 = "Yabby3",
}

export enum EmergencyStatus {
  NEW = "NEW",
  RESOLVED = "RESOLVED",
}

export enum EmergencyType {
  EMERGENCY = "EMERGENCY",
  PRIORITY_ALERT = "PRIORITY_ALERT",
}

export enum MapMarkerIcon {
  AIRPLANE = "AIRPLANE",
  ATV = "ATV",
  BOAT = "BOAT",
  BOX_TRUCK = "BOX_TRUCK",
  BULLDOZER = "BULLDOZER",
  BUS = "BUS",
  CAMERA = "CAMERA",
  CAR = "CAR",
  CARAVAN = "CARAVAN",
  DUMP_TRUCK = "DUMP_TRUCK",
  ELECTRIC_CAR = "ELECTRIC_CAR",
  ENFORCEMENT = "ENFORCEMENT",
  ERROR = "ERROR",
  EXCAVATOR = "EXCAVATOR",
  FORKLIFT = "FORKLIFT",
  GENERATOR = "GENERATOR",
  GPS_TRACKER = "GPS_TRACKER",
  GRADER = "GRADER",
  HARVESTER = "HARVESTER",
  HELICOPTER = "HELICOPTER",
  HOUSE = "HOUSE",
  LARGE_TRAILER = "LARGE_TRAILER",
  LOADER = "LOADER",
  LOG_TRUCK = "LOG_TRUCK",
  MOBILE_RADIO = "MOBILE_RADIO",
  MOPED = "MOPED",
  MOTORCYCLE = "MOTORCYCLE",
  PERSON = "PERSON",
  PERSON_WALKING = "PERSON_WALKING",
  PHONE = "PHONE",
  PICKUP_TRUCK = "PICKUP_TRUCK",
  PORTABLE_RADIO = "PORTABLE_RADIO",
  PUMP = "PUMP",
  PUSH_BIKE = "PUSH_BIKE",
  QUESTION_MARK = "QUESTION_MARK",
  SHUTTLE = "SHUTTLE",
  SKID_STEER = "SKID_STEER",
  TAXI = "TAXI",
  TELELIFT = "TELELIFT",
  TRACTOR = "TRACTOR",
  TRAILER = "TRAILER",
  TRAIN = "TRAIN",
  TRUCK = "TRUCK",
  WARNING = "WARNING",
}

export enum MapMarkerShape {
  CIRCLE = "CIRCLE",
  SQUARE = "SQUARE",
}

export enum NetworkType {
  CHATTER_PTT = "CHATTER_PTT",
  GENERIC = "GENERIC",
  HYTERA_TIER_2 = "HYTERA_TIER_2",
  TAIT_TIER_2 = "TAIT_TIER_2",
  TAIT_TIER_3 = "TAIT_TIER_3",
  TELTONIKA = "TELTONIKA",
}

export enum ReportProcessingStatus {
  COMPLETE = "COMPLETE",
  FAILED = "FAILED",
  PROCESSING = "PROCESSING",
  QUEUED = "QUEUED",
}

export enum ReportType {
  ACTIVITY = "ACTIVITY",
  ASSET_EVENT_HISTORY = "ASSET_EVENT_HISTORY",
  ASSET_LOG_HISTORY = "ASSET_LOG_HISTORY",
  BILLING = "BILLING",
  DAILY = "DAILY",
  DRIVER = "DRIVER",
  GEOFENCE = "GEOFENCE",
  LOCATION = "LOCATION",
  SAFETY_TIMER = "SAFETY_TIMER",
  USER_AUTH_AUDIT = "USER_AUTH_AUDIT",
}

export enum TeltonikaDeviceModelType {
  FMC130 = "FMC130",
  FMC230 = "FMC230",
  FMC640 = "FMC640",
}

/**
 * Input object for ActivateDevicesInput).
 */
export interface ActivateDevicesInput {
  ids: any[];
}

/**
 * Input object for AddATrackDeviceInput).
 */
export interface AddATrackDeviceInput {
  modelType?: ATrackDeviceModelType | null;
  imei: string;
  phoneNumber?: string | null;
  name: string;
  serialNumber?: string | null;
  dealerId?: any | null;
  clientId?: any | null;
  asset?: AddDeviceAssetInput | null;
}

/**
 * Input object for AddAssetInput).
 */
export interface AddAssetInput {
  name: string;
  dealerId?: any | null;
  clientId?: any | null;
  assetTypeId?: any | null;
  speedLimit?: number | null;
  emergencyNotes?: string | null;
  billingType?: AssetBillingType | null;
  trialPeriodMonths?: number | null;
}

/**
 * Input object for AddAssetServiceReminderInput).
 */
export interface AddAssetServiceReminderInput {
  assetServiceReminderTypeId: any;
  nextDateTrigger?: any | null;
  dateDefaultFrequency?: number | null;
  nextOdometerTrigger?: number | null;
  odometerDefaultFrequency?: number | null;
  nextEngineTimeTrigger?: number | null;
  engineTimeDefaultFrequency?: number | null;
  assetId: any;
  extraInformation?: string | null;
  usersIds?: any[] | null;
  usersGroupIds?: any[] | null;
}

/**
 * Input object for AddAssetServiceReminderTypeInput).
 */
export interface AddAssetServiceReminderTypeInput {
  name: string;
  clientId: any;
  message?: string | null;
  dateEnabled?: boolean | null;
  dateWhenToRemindInAdvance?: number | null;
  odometerEnabled?: boolean | null;
  odometerWhenToRemindInAdvance?: number | null;
  engineTimeEnabled?: boolean | null;
  engineTimeWhenToRemindInAdvance?: number | null;
}

/**
 * Request to add a telematics reading for an asset.
 */
export interface AddAssetTelematicsReadingInput {
  assetId: any;
  value: number;
  type: AssetTelematicsType;
}

/**
 * Input object for AddBluetoothBeaconInput).
 */
export interface AddBluetoothBeaconInput {
  name: string;
  serialNumber: string;
  transmitPower: number;
  beaconType: BluetoothBeaconType;
  dealerId?: any | null;
}

/**
 * Input object for AddCamperVanDeviceInput).
 */
export interface AddCamperVanDeviceInput {
  imei: string;
  name: string;
  serialNumber?: string | null;
  dealerId?: any | null;
  clientId?: any | null;
  asset?: AddDeviceAssetInput | null;
}

/**
 * Input object for AddCustomMapMarkerInput).
 */
export interface AddCustomMapMarkerInput {
  name: string;
  clientId?: any | null;
  details?: string | null;
  floorPlanId?: any | null;
  longitude: number;
  latitude: number;
  backgroundShape?: MapMarkerShape | null;
  backgroundShapeColor?: string | null;
  icon?: MapMarkerIcon | null;
  iconColor?: string | null;
  size: number;
}

/**
 * Input object for adding a dealer.
 */
export interface AddDealerInput {
  name: string;
  type: DealerType;
  maxTrialMonths: number;
  billingCode?: string | null;
  assetTrialEndNotificationEnabled: boolean;
  assetTrialEndNotificationPeriodDays?: number | null;
  navbarBrandingImageFile?: any | null;
}

/**
 * Asset information.
 */
export interface AddDeviceAssetInput {
  name?: string | null;
  assetTypeId?: any | null;
  speedLimit?: number | null;
  emergencyNotes?: string | null;
  billingType?: AssetBillingType | null;
  trialPeriodMonths?: number | null;
}

/**
 * Input object for AddDigitalMatterDeviceMutation.
 */
export interface AddDigitalMatterDeviceInput {
  modelType?: DigitalMatterDeviceModelType | null;
  imei: string;
  phoneNumber?: string | null;
  name: string;
  serialNumber?: string | null;
  dealerId?: any | null;
  clientId?: any | null;
  asset?: AddDeviceAssetInput | null;
}

/**
 * Input object for AddEroadDeviceInput).
 */
export interface AddEroadDeviceInput {
  vehiclePlate: string;
  name: string;
  serialNumber?: string | null;
  dealerId?: any | null;
  clientId?: any | null;
  asset?: AddDeviceAssetInput | null;
}

/**
 * Input object for AddGenericDeviceInput).
 */
export interface AddGenericDeviceInput {
  networkId?: any | null;
  networkDeviceId?: string | null;
  gpsEnabled: boolean;
  bleEnabled: boolean;
  bluetoothBeaconSetIds?: any[] | null;
  manualPollingEnabled: boolean;
  name: string;
  serialNumber?: string | null;
  dealerId?: any | null;
  clientId?: any | null;
  asset?: AddDeviceAssetInput | null;
}

/**
 * Input object for AddHyteraRadioDeviceInput).
 */
export interface AddHyteraRadioDeviceInput {
  networkId?: any | null;
  radioId?: string | null;
  gpsEnabled: boolean;
  bleEnabled: boolean;
  bluetoothBeaconSetIds?: any[] | null;
  name: string;
  serialNumber?: string | null;
  dealerId?: any | null;
  clientId?: any | null;
  asset?: AddDeviceAssetInput | null;
}

/**
 * Input object for AddMobilePhoneDeviceInput).
 */
export interface AddMobilePhoneDeviceInput {
  networkId?: any | null;
  chatterPttUserId?: string | null;
  callRecordingEnabled: boolean;
  bluetoothBeaconSetIds?: any[] | null;
  name: string;
  serialNumber?: string | null;
  dealerId?: any | null;
  clientId?: any | null;
  asset?: AddDeviceAssetInput | null;
}

/**
 * Input object for AddTaitRadioDeviceInput).
 */
export interface AddTaitRadioDeviceInput {
  networkId?: any | null;
  radioId?: string | null;
  selcallId?: string | null;
  gpsEnabled: boolean;
  bleEnabled: boolean;
  bluetoothBeaconSetIds?: any[] | null;
  callRecordingEnabled: boolean;
  manualPollingEnabled: boolean;
  name: string;
  serialNumber?: string | null;
  dealerId?: any | null;
  clientId?: any | null;
  asset?: AddDeviceAssetInput | null;
}

/**
 * Input object for AddTeltonikaDeviceInput).
 */
export interface AddTeltonikaDeviceInput {
  modelType?: TeltonikaDeviceModelType | null;
  imei: string;
  phoneNumber?: string | null;
  networkId?: any | null;
  name: string;
  serialNumber?: string | null;
  dealerId?: any | null;
  clientId?: any | null;
  asset?: AddDeviceAssetInput | null;
}

/**
 * Input object for AssignDeviceToAssetInput).
 */
export interface AssignDeviceToAssetInput {
  assetId: any;
  deviceId: any;
}

/**
 * Input object for DeactivateDevicesInput).
 */
export interface DeactivateDevicesInput {
  ids: any[];
}

/**
 * Input object for DeleteAssetServiceReminderTypesMutation.
 */
export interface DeleteAssetServiceReminderTypesInput {
  ids: any[];
}

/**
 * Input object for deleting a asset service reminders;
 */
export interface DeleteAssetServiceRemindersInput {
  ids: any[];
}

/**
 * Input object for DeleteAssetsInput).
 */
export interface DeleteAssetsInput {
  ids: any[];
}

/**
 * Input object for DeleteBluetoothBeaconsInput).
 */
export interface DeleteBluetoothBeaconsInput {
  ids: any[];
}

/**
 * Input object for DeleteCustomMapMarkersInput).
 */
export interface DeleteCustomMapMarkersInput {
  ids: any[];
}

/**
 * Input object for DeleteDevicesInput).
 */
export interface DeleteDevicesInput {
  ids: any[];
}

/**
 * Input object for PollDeviceLocationInput).
 */
export interface PollDeviceLocationInput {
  deviceId: any;
}

/**
 * Input object for rebooting a gateway
 */
export interface RebootGatewayInput {
  gatewayId: any;
}

/**
 * Input object for ResolveAssetServiceReminderInput).
 */
export interface ResolveAssetServiceReminderInput {
  assetReminderId: any;
  nextDateTrigger?: any | null;
  nextOdometerTrigger?: number | null;
  nextEngineTimeTrigger?: number | null;
  notes?: string | null;
}

/**
 * Input object for UnassignDeviceFromAssetInput).
 */
export interface UnassignDeviceFromAssetInput {
  assetId: any;
  deviceId: any;
}

/**
 * Input object for UpdateATrackDeviceInput).
 */
export interface UpdateATrackDeviceInput {
  modelType?: ATrackDeviceModelType | null;
  imei?: string | null;
  phoneNumber?: string | null;
  deviceId: any;
  name?: string | null;
  serialNumber?: string | null;
  dealerId?: any | null;
  clientId?: any | null;
}

/**
 * Input object for UpdateAssetInput).
 */
export interface UpdateAssetInput {
  assetId: any;
  name?: string | null;
  dealerId?: any | null;
  clientId?: any | null;
  assetTypeId?: any | null;
  speedLimit?: number | null;
  emergencyNotes?: string | null;
  billingType?: AssetBillingType | null;
  trialPeriodEndTimestamp?: any | null;
}

/**
 * Input object for UpdateAssetServiceReminderInput).
 */
export interface UpdateAssetServiceReminderInput {
  assetReminderId: any;
  nextDateTrigger?: any | null;
  dateDefaultFrequency?: number | null;
  nextOdometerTrigger?: number | null;
  odometerDefaultFrequency?: number | null;
  nextEngineTimeTrigger?: number | null;
  engineTimeDefaultFrequency?: number | null;
  extraInformation?: string | null;
  usersToAdd?: any[] | null;
  usersToDelete?: any[] | null;
  userGroupsToAdd?: any[] | null;
  userGroupsToDelete?: any[] | null;
}

/**
 * Input object for UpdateAssetServiceReminderTypeInput).
 */
export interface UpdateAssetServiceReminderTypeInput {
  assetServiceReminderTypeId: any;
  name?: string | null;
  message?: string | null;
  dateEnabled?: boolean | null;
  dateWhenToRemindInAdvance?: number | null;
  odometerEnabled?: boolean | null;
  odometerWhenToRemindInAdvance?: number | null;
  engineTimeEnabled?: boolean | null;
  engineTimeWhenToRemindInAdvance?: number | null;
}

/**
 * Input object for UpdateAssetServiceReminderUsersAndUserGroupsInput).
 */
export interface UpdateAssetServiceReminderUsersAndUserGroupsInput {
  assetReminderIds: any[];
  usersToAdd?: any[] | null;
  usersToDelete?: any[] | null;
  userGroupsToAdd?: any[] | null;
  userGroupsToDelete?: any[] | null;
}

/**
 * Input object for UpdateBluetoothBeaconInput).
 */
export interface UpdateBluetoothBeaconInput {
  bluetoothBeaconId: any;
  name?: string | null;
  serialNumber?: string | null;
  beaconType?: BluetoothBeaconType | null;
  transmitPower?: number | null;
  disabled?: boolean | null;
  dealerId?: any | null;
  floorPlanId?: any | null;
  longitude?: number | null;
  latitude?: number | null;
}

/**
 * Input object for UpdateCamperVanDeviceInput).
 */
export interface UpdateCamperVanDeviceInput {
  imei?: string | null;
  deviceId: any;
  name?: string | null;
  serialNumber?: string | null;
  dealerId?: any | null;
  clientId?: any | null;
}

/**
 * Input object for UpdateCustomMapMarkerInput).
 */
export interface UpdateCustomMapMarkerInput {
  customMapMarkerId: any;
  name?: string | null;
  details?: string | null;
  floorPlanId?: any | null;
  longitude?: number | null;
  latitude?: number | null;
  backgroundShape?: MapMarkerShape | null;
  backgroundShapeColor?: string | null;
  icon?: MapMarkerIcon | null;
  iconColor?: string | null;
  size?: number | null;
}

/**
 * Input object for Updating a dealer.
 */
export interface UpdateDealerInput {
  dealerId: any;
  name?: string | null;
  type?: DealerType | null;
  maxTrialMonths?: number | null;
  billingCode?: string | null;
  assetTrialEndNotificationEnabled?: boolean | null;
  assetTrialEndNotificationPeriodDays?: number | null;
  navbarBrandingImageFile?: any | null;
}

/**
 * Input object type for updating one devices IO configurations.
 */
export interface UpdateDeviceIoConfigurationsInput {
  deviceId: any;
  ioConfigurations: UpdateIoConfigurationsInput[];
}

/**
 * Input object for updating a devices IO configurations.
 */
export interface UpdateDevicesIoConfigurationsInput {
  devices: UpdateDeviceIoConfigurationsInput[];
}

/**
 * Input object for UpdateDigitalMatterDeviceInput.
 */
export interface UpdateDigitalMatterDeviceInput {
  modelType?: DigitalMatterDeviceModelType | null;
  imei?: string | null;
  phoneNumber?: string | null;
  deviceId: any;
  name?: string | null;
  serialNumber?: string | null;
  dealerId?: any | null;
  clientId?: any | null;
}

/**
 * Input object for UpdateEroadDeviceInput).
 */
export interface UpdateEroadDeviceInput {
  vehiclePlate?: string | null;
  deviceId: any;
  name?: string | null;
  serialNumber?: string | null;
  dealerId?: any | null;
  clientId?: any | null;
}

/**
 * Input object for updating a gateways version.
 */
export interface UpdateGatewayVersionInput {
  gatewayId: any;
  gatewayVersionId: any;
}

/**
 * Input object for UpdateGenericDeviceMutation.
 */
export interface UpdateGenericDeviceInput {
  networkId?: any | null;
  networkDeviceId?: string | null;
  gpsEnabled?: boolean | null;
  bleEnabled?: boolean | null;
  bluetoothBeaconSetIds?: any[] | null;
  manualPollingEnabled?: boolean | null;
  deviceId: any;
  name?: string | null;
  serialNumber?: string | null;
  dealerId?: any | null;
  clientId?: any | null;
}

/**
 * Input object for UpdateHyteraRadioDeviceInput).
 */
export interface UpdateHyteraRadioDeviceInput {
  networkId?: any | null;
  radioId?: string | null;
  gpsEnabled?: boolean | null;
  bleEnabled?: boolean | null;
  bluetoothBeaconSetIds?: any[] | null;
  deviceId: any;
  name?: string | null;
  serialNumber?: string | null;
  dealerId?: any | null;
  clientId?: any | null;
}

/**
 * Input object for updating a IO configuration.
 */
export interface UpdateIoConfigurationsInput {
  id?: any | null;
  name?: string | null;
  isEnabled?: boolean | null;
  ioName?: DeviceIoName | null;
  onAction?: DeviceIoAction | null;
  offAction?: DeviceIoAction | null;
}

/**
 * Input object for UpdateMobilePhoneDeviceInput).
 */
export interface UpdateMobilePhoneDeviceInput {
  networkId?: any | null;
  chatterPttUserId?: string | null;
  callRecordingEnabled?: boolean | null;
  bluetoothBeaconSetIds?: any[] | null;
  deviceId: any;
  name?: string | null;
  serialNumber?: string | null;
  dealerId?: any | null;
  clientId?: any | null;
}

/**
 * Input object for UpdateTaitRadioDeviceInput).
 */
export interface UpdateTaitRadioDeviceInput {
  networkId?: any | null;
  radioId?: string | null;
  selcallId?: string | null;
  gpsEnabled?: boolean | null;
  bleEnabled?: boolean | null;
  bluetoothBeaconSetIds?: any[] | null;
  callRecordingEnabled?: boolean | null;
  manualPollingEnabled?: boolean | null;
  deviceId: any;
  name?: string | null;
  serialNumber?: string | null;
  dealerId?: any | null;
  clientId?: any | null;
}

/**
 * Input object for UpdateTeltonikaDeviceInput).
 */
export interface UpdateTeltonikaDeviceInput {
  modelType?: TeltonikaDeviceModelType | null;
  imei?: string | null;
  phoneNumber?: string | null;
  networkId?: any | null;
  deviceId: any;
  name?: string | null;
  serialNumber?: string | null;
  dealerId?: any | null;
  clientId?: any | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
