import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryEditDevice($id: UUID!, $includeDealers: Boolean!, $includeClients: Boolean!) {
		bluetoothBeaconSets {
			id
			name
			dealer @include(if: $includeDealers) {
				id
			}
		}

		dealers @include(if: $includeDealers) {
			id
			name
		}

		clients @include(if: $includeClients) {
			id
			name
			dealer @include(if: $includeDealers) {
				id
			}
		}

		device: deviceById(id: $id) {
			id
			deviceType
			name
			serialNumber
			activationState
			dealer @include(if: $includeDealers) {
				id
			}
			client @include(if: $includeClients) {
				id
			}
			asset {
				id
				name
				billingType
				trialPeriodEndTimestamp
			}
			... on ATrackDevice {
				atrackModelType: modelType
				imei
				phoneNumber
			}
			... on CamperVanDevice {
				imei
			}
			... on DigitalMatterDevice {
				digitalMatterModelType: modelType
				imei
				phoneNumber
				serialNumber
			}
			... on EroadDevice {
				vehiclePlate
			}
			... on HyteraRadioDevice {
				network {
					id
				}
				radioId
				gpsEnabled
				bleEnabled
				bluetoothBeaconSets {
					id
				}
			}
			... on GenericDevice {
				network {
					id
				}
				networkDeviceId
				gpsEnabled
				bleEnabled
				manualPollingEnabled
				bluetoothBeaconSets {
					id
				}
			}
			... on MobilePhoneDevice {
				network {
					id
				}
				chatterPttUserId
				callRecordingEnabled
				bluetoothBeaconSets {
					id
				}
			}
			... on TaitRadioDevice {
				network {
					id
				}
				radioId
				selcallId
				gpsEnabled
				bleEnabled
				callRecordingEnabled
				manualPollingEnabled
				bluetoothBeaconSets {
					id
				}
			}
			... on TeltonikaDevice {
				teltonikaModelType: modelType
				imei
				phoneNumber
				network {
					id
				}
			}
		}
	}
`;
