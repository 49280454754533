import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryMapAssets {
		assets {
			id
			name
			emergencyNotes
			billingType
			client {
				id
				name
			}
			assetType {
				id
				name
			}
			devices {
				id
				deviceType
				activationState
				... on HyteraRadioDevice {
					network {
						id
					}
				}
				... on GenericDevice {
					manualPollingEnabled
					network {
						id
					}
				}
				... on TaitRadioDevice {
					manualPollingEnabled
					network {
						id
					}
				}
				... on TeltonikaDevice {
					network {
						id
					}
				}
			}
		}
	}
`;
