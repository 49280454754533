import { Client as C } from 'src/services';
import { DeviceType } from 'src/../__generated__/globalTypes';
import { IOption, getPrettyName } from 'src/util';

const possibleDeviceTypeOptions = [
	DeviceType.ATRACK,
	DeviceType.CAMPER_VAN,
	DeviceType.DIGITAL_MATTER,
	DeviceType.EROAD,
	DeviceType.HYTERA_RADIO,
	DeviceType.GENERIC,
	DeviceType.MOBILE_PHONE,
	DeviceType.TAIT_RADIO,
	DeviceType.TELTONIKA,
];

const shouldAddDeviceTypeOption = (identity: C.IIdentityDto, deviceType: DeviceType, currentDeviceType?: DeviceType | null) => {
	// Always give an option for the device's current type.
	if (deviceType === currentDeviceType)
		return true;

	if (currentDeviceType != null)
		return false;

	switch (deviceType) {
		case DeviceType.ATRACK:
		case DeviceType.CAMPER_VAN:
		case DeviceType.EROAD:
		case DeviceType.TELTONIKA:
		case DeviceType.DIGITAL_MATTER:
			return identity.type === C.IdentityType.SuperUser;

		case DeviceType.HYTERA_RADIO:
		case DeviceType.GENERIC:
		case DeviceType.MOBILE_PHONE:
		case DeviceType.TAIT_RADIO:
			return identity.type === C.IdentityType.SuperUser || identity.type === C.IdentityType.Dealer;
	}
};

export const getDeviceTypeOptions = (identity: C.IIdentityDto, currentDeviceType?: DeviceType | null): IOption<DeviceType>[] => {
	return possibleDeviceTypeOptions
		.filter(option => shouldAddDeviceTypeOption(identity, option, currentDeviceType))
		.map(x => ({ value: x, label: getPrettyName(x) }));
};
