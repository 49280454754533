import moment from 'moment-timezone';

import { AssetBillingType } from 'src/../__generated__/globalTypes';
import { DeviceType } from 'src/../__generated__/globalTypes';
import { QueryEditDevice_device, QueryEditDevice_device_ATrackDevice, QueryEditDevice_device_CamperVanDevice, QueryEditDevice_device_DigitalMatterDevice, QueryEditDevice_device_EroadDevice, QueryEditDevice_device_GenericDevice, QueryEditDevice_device_HyteraRadioDevice, QueryEditDevice_device_MobilePhoneDevice, QueryEditDevice_device_TaitRadioDevice, QueryEditDevice_device_TeltonikaDevice } from 'src/graphql/__generated__/queries/queryEditDevice';

import { useCurrentUser } from 'src/services';
import { IOption } from 'src/util';
import { ManageDeviceFormValues } from './manageDeviceComponent';

export function isATrackDevice(device: QueryEditDevice_device): device is QueryEditDevice_device_ATrackDevice {
	return device.deviceType === DeviceType.ATRACK;
}

export function isCamperVanDevice(device: QueryEditDevice_device): device is QueryEditDevice_device_CamperVanDevice {
	return device.deviceType === DeviceType.CAMPER_VAN;
}

export function isDigitalMatterDevice(device: QueryEditDevice_device): device is QueryEditDevice_device_DigitalMatterDevice {
	return device.deviceType === DeviceType.DIGITAL_MATTER;
}

export function isEroadDevice(device: QueryEditDevice_device): device is QueryEditDevice_device_EroadDevice {
	return device.deviceType === DeviceType.EROAD;
}

export function isHyteraRadioDevice(device: QueryEditDevice_device): device is QueryEditDevice_device_HyteraRadioDevice {
	return device.deviceType === DeviceType.HYTERA_RADIO;
}

export function isGenericDevice(device: QueryEditDevice_device): device is QueryEditDevice_device_GenericDevice {
	return device.deviceType === DeviceType.GENERIC;
}

export function isMobilePhoneDevice(device: QueryEditDevice_device): device is QueryEditDevice_device_MobilePhoneDevice {
	return device.deviceType === DeviceType.MOBILE_PHONE;
}

export function isTaitRadioDevice(device: QueryEditDevice_device): device is QueryEditDevice_device_TaitRadioDevice {
	return device.deviceType === DeviceType.TAIT_RADIO;
}

export function isTeltonikaDevice(device: QueryEditDevice_device): device is QueryEditDevice_device_TeltonikaDevice {
	return device.deviceType === DeviceType.TELTONIKA;
}

export function SelectDefaultBillingType(assetBillingTypeOptions: IOption<AssetBillingType>[]): AssetBillingType {
	if (assetBillingTypeOptions.some(x => x.value === AssetBillingType.STANDARD))
		return AssetBillingType.STANDARD;

	return assetBillingTypeOptions[0].value;
}

export const getInitialFormValues = (device: QueryEditDevice_device | undefined, billingTypeOptions: IOption<AssetBillingType>[], maxTrialPeriodMonths: number): ManageDeviceFormValues => {
	const currentUser = useCurrentUser()!;

	let trialPeriodEndTimestamp: moment.Moment | null = null;
	if (device?.asset?.trialPeriodEndTimestamp) {
		trialPeriodEndTimestamp = moment(device?.asset?.trialPeriodEndTimestamp);
	}

	const formValues: ManageDeviceFormValues = {
		// General asset/device information.
		dealerId: device?.dealer?.id ?? null,
		clientId: device?.client?.id ?? null,

		// Device only information.
		deviceSameNameAsAsset: true,
		deviceName: device?.name ?? '',
		serialNumber: device?.serialNumber ?? '',
		deviceType: device?.deviceType ?? null,

		// Asset only information.
		assetName: '',
		assetTypeId: null,
		billingType: device?.asset?.billingType ?? SelectDefaultBillingType(billingTypeOptions),
		speedLimitEnabled: false,
		speedLimit: currentUser.usesMetric ? 110 : 70,
		emergencyNotes: '',
		trialPeriodEndTimestamp: trialPeriodEndTimestamp,
		trialPeriodMonths: Math.min(3, maxTrialPeriodMonths),

		// Only applicable to certain device types.
		atrackModelType: null,
		teltonikaModelType: null,
		digitalMatterModelType: null,
		networkId: null,
		networkDeviceId: '',
		radioId: '',
		selCallId: '',
		gpsEnabled: true,
		bleEnabled: false,
		bluetoothBeaconSetIds: [],
		manualPollingEnabled: false,
		callRecordingEnabled: false,
		imei: '',
		phoneNumber: '',
		vehiclePlate: '',
		chatterPttUserId: '',
	};

	if (!device)
		return formValues;

	if (isATrackDevice(device)) {
		formValues.atrackModelType = device.atrackModelType;
		formValues.imei = device.imei ?? formValues.imei;
		formValues.phoneNumber = device.phoneNumber ?? formValues.phoneNumber;
	}

	if (isCamperVanDevice(device)) {
		formValues.imei = device.imei ?? formValues.imei;
	}

	if (isDigitalMatterDevice(device)) {
		formValues.digitalMatterModelType = device.digitalMatterModelType;
		formValues.imei = device.imei ?? formValues.imei;
		formValues.phoneNumber = device.phoneNumber ?? formValues.phoneNumber;
	}

	if (isEroadDevice(device)) {
		formValues.vehiclePlate = device.vehiclePlate ?? formValues.vehiclePlate;
	}

	if (isHyteraRadioDevice(device)) {
		formValues.networkId = device.network?.id ?? formValues.networkId;
		formValues.radioId = device.radioId ?? formValues.radioId;
		formValues.gpsEnabled = device.gpsEnabled ?? formValues.gpsEnabled;
		formValues.bleEnabled = device.bleEnabled ?? formValues.bleEnabled;
		formValues.bluetoothBeaconSetIds = device.bluetoothBeaconSets?.map(x => x?.id) ?? null;
	}

	if (isGenericDevice(device)) {
		formValues.networkId = device.network?.id ?? formValues.networkId;
		formValues.networkDeviceId = device.networkDeviceId ?? formValues.networkDeviceId;
		formValues.gpsEnabled = device.gpsEnabled ?? formValues.gpsEnabled;
		formValues.bleEnabled = device.bleEnabled ?? formValues.bleEnabled;
		formValues.manualPollingEnabled = device.manualPollingEnabled ?? formValues.manualPollingEnabled;
		formValues.bluetoothBeaconSetIds = device.bluetoothBeaconSets?.map(x => x?.id) ?? null;
	}

	if (isMobilePhoneDevice(device)) {
		formValues.networkId = device.network?.id ?? formValues.networkId;
		formValues.callRecordingEnabled = device.callRecordingEnabled ?? formValues.callRecordingEnabled;
		formValues.chatterPttUserId = device.chatterPttUserId ?? formValues.chatterPttUserId;
		formValues.bluetoothBeaconSetIds = device.bluetoothBeaconSets?.map(x => x?.id) ?? null;
	}

	if (isTaitRadioDevice(device)) {
		formValues.networkId = device.network?.id ?? formValues.networkId;
		formValues.radioId = device.radioId ?? formValues.radioId;
		formValues.selCallId = device.selcallId ?? formValues.selCallId;
		formValues.gpsEnabled = device.gpsEnabled ?? formValues.gpsEnabled;
		formValues.bleEnabled = device.bleEnabled ?? formValues.bleEnabled;
		formValues.manualPollingEnabled = device.manualPollingEnabled ?? formValues.manualPollingEnabled;
		formValues.callRecordingEnabled = device.callRecordingEnabled ?? formValues.callRecordingEnabled;
		formValues.bluetoothBeaconSetIds = device.bluetoothBeaconSets?.map(x => x?.id) ?? null;
	}

	if (isTeltonikaDevice(device)) {
		formValues.teltonikaModelType = device.teltonikaModelType;
		formValues.imei = device.imei ?? formValues.imei;
		formValues.phoneNumber = device.phoneNumber ?? formValues.phoneNumber;
		formValues.networkId = device.network?.id ?? formValues.networkId;
	}

	return formValues;
};
