import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryUserGroupCallGroupsPage {
		callGroups {
			id
			name
			client {
				id
				name
			}
		}
	}
`;
