import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { styled } from '@material-ui/core/styles';

import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AssessmentIcon from '@material-ui/icons/Assessment';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import BusinessIcon from '@material-ui/icons/Business';
import CallIcon from '@material-ui/icons/Call';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CommuteIcon from '@material-ui/icons/Commute';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLessRounded';
import FormatShapesIcon from '@material-ui/icons/FormatShapes';
import HelpIcon from '@material-ui/icons/Help';
import HistoryIcon from '@material-ui/icons/History';
import LayersIcon from '@material-ui/icons/Layers';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import MenuIcon from '@material-ui/icons/Menu';
import PeopleIcon from '@material-ui/icons/People';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import PublicIcon from '@material-ui/icons/Public';
import RouterIcon from '@material-ui/icons/Router';
import SettingsIcon from '@material-ui/icons/Settings';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import UndoIcon from '@material-ui/icons/Undo';
import WifiTetheringIcon from '@material-ui/icons/WifiTethering';
import WorkIcon from '@material-ui/icons/Work';

import {
	Client as C,
	AuthenticationService,
	BrandingService,
	EmergencyService,
	HistoryService,
	Service,
	ToasterService,
	WebSocketService,
	useInjection,
} from 'src/services';

import { Button, PopoverMenu, PopoverMenuItem } from 'src/components';

import { AnyItem, isUrlItem, isSubItem, isActionItem, ActionItem } from 'src/util/menuItems';

import { Welcome } from './welcome';
import { PageNotFound } from './pageNotFound';

import { AddressBooksList } from './addressBooks/addressBooksList';
import { ManageAddressBooks } from './addressBooks/manageAddressBooks';
import { EditAddressBooks } from './addressBooks/editAddressBooks';

import { AssetServiceRemindersTypesList } from './assetServiceReminders/assetServiceReminderTypesList';
import { EditAssetServiceReminderType } from './assetServiceReminders/editAssetServiceReminderType';
import { ManageAssetServiceReminderType } from './assetServiceReminders/manageAssetServiceReminderType';

import { AssetServiceRemindersHistoryList } from './assetServiceReminders/assetServiceRemindersHistoryList';
import { AssetServiceRemindersList } from './assetServiceReminders/assetServiceRemindersList';
import { EditAssetServiceReminder } from './assetServiceReminders/editAssetServiceReminder';
import { AddAssetServiceReminderForReminderType } from './assetServiceReminders/addAssetServiceReminderForReminderType';
import { AddAssetServiceReminderForAsset } from './assetServiceReminders/addAssetServiceReminderForAsset';

import { AddFloorPlan } from './floorPlans/addFloorPlan';
import { FloorPlanCollectionsList } from './floorPlans/floorPlanCollectionsList';
import { FloorPlansList } from './floorPlans/floorPlansList';
import { EditFloorPlan } from './floorPlans/editFloorPlan';

import { AssetsList } from './assets/assetsList';
import { EditAsset } from './assets/editAsset';
import { AddAsset } from './assets/addAsset';
import { AddAssetWithDevice } from './assets/addAssetWithDevice';
import { ImportAssetsFromFile } from './assets/importAssetsFromFile';
import { VehicleInformation } from './vehicleInformation/vehicleInformation';
import { VehicleInformationList } from './vehicleInformation/vehicleInformationList';

import { AssetGroupsList } from './assetGroups/assetGroupsList';
import { AddAssetGroup } from './assetGroups/addAssetGroup';
import { EditAssetGroup } from './assetGroups/editAssetGroup';
import { ManageAssetGroup } from './assetGroups/manageAssetGroup';

import { AssetTypesList } from './assetTypes/assetTypesList';
import { AddAssetType } from './assetTypes/addAssetTypes';
import { EditAssetType } from './assetTypes/editAssetType';

import { DeviceConfigurationsList } from './deviceConfigurations/deviceConfigurationsList';
import { AddDeviceConfiguration } from './deviceConfigurations/addDeviceConfiguration';

import { LinkedDeviceList } from './devices/linkedDeviceList';

import { BeaconsList } from './beacons/beaconsList';
import { AddBeacon } from './beacons/addBeacon';
import { EditBeacon } from './beacons/editBeacon';
import { PlaceBeacon } from './beacons/placeBeacon';

import { AddBleBeaconSet } from './beaconSets/addBleBeaconSet';
import { BleBeaconSetsList } from './beaconSets/bleBeaconSetsList';
import { EditBleBeaconSet } from './beaconSets/editBleBeaconSet';
import { ManageBleBeaconSet } from './beaconSets/manageBleBeaconSet';

import { AddGateway } from './gateways/addGateway';
import { EditGateway } from './gateways/editGateway';
import { GatewayList } from './gateways/gatewayList';
import { GatewayOverview } from './gateways/gatewayOverview';
import { GatewayStatus } from './gateways/gatewayStatus';

import { AddEditSite } from './sites/addEditSite';
import { SiteList } from './sites/siteList';

import { CallsIndex } from './calls';
import { CallGroupsList } from './callGroups/callGroupsList';

import { AddClient } from './clients/addClient';
import { EditClient } from './clients/editClient';
import { ClientsList } from './clients/clientsList';
import { ClientOverview } from './clients/clientOverview';

import { AddDealer } from './dealers/addDealer';
import { EditDealer } from './dealers/editDealer';
import { DealersList } from './dealers/dealersList';

import { AddDevice } from './devices/addDevice';
import { EditDevice } from './devices/editDevice';
import { DevicesList } from './devices/devicesList';

import { IoConfigurationsList } from './deviceIoConfigurations/ioConfigurationsList';

import { EmergencyDetails } from './emergencies/emergencyDetails';
import { EmergencyList } from './emergencies/emergencyList';
import { EmergencyHistoryList } from './emergencies/emergencyHistoryList';

import { EnableFleetConfigurationsList } from './enableFleet/enableFleetConfigurationsList';
import { AddEnableFleetConfiguration } from './enableFleet/addEnableFleetConfiguration';
import { EditEnableFleetConfiguration } from './enableFleet/editEnableFleetConfiguration';
import { EditEnableFleetConfigurationFields } from './enableFleet/editEnableFleetConfigurationFields';
import { ManageEnableFleetPermissions } from './enableFleet/manageEnableFleetPermissions';
import { ConfigureEnableFleetAsset } from './enableFleet/configureEnableFleetAsset';

import { GeofenceList } from './geofences/geofenceList';
import { GeofenceTypeList } from './geofences/geofenceTypeList';
import { AddGeofenceType } from './geofences/addGeofenceType';
import { EditGeofenceType } from './geofences/editGeofenceType';

import { AddUser } from './users/addUser';
import { EditUser } from './users/editUser';
import { UsersList } from './users/usersList';
import { ManageUserGroups } from './users/manageUserGroups';
import { ChangePassword } from './users/changePassword';
import { Account } from './users/account';
import { UserGatewayAlertSettings } from './users/userGatewayAlertSettings';
import { EditUserAlertSettings } from './users/editUserAlertSettings';

import { ReportsIndex } from './reports';
import { CreateReport } from './reports/createReport';
import { ScheduledReportsList } from './reports/scheduled/scheduledReportsList';
import { CreateScheduledReport } from './reports/scheduled/createScheduledReport';
import { EditScheduledReport } from './reports/scheduled/editScheduledReport';

import { CreateActivityReport } from './reports/create/activity';
import { CreateAssetEventsExport } from './reports/create/assetEventsExport';
import { CreateAssetLogHistoryReport } from './reports/create/assetLogHistory';
import { CreateBillingReport } from './reports/create/billing';
import { CreateDailyReport } from './reports/create/daily';
import { CreateDriverReport } from './reports/create/driver';
import { CreateGeofenceReport } from './reports/create/geofence';
import { CreateLocationReport } from './reports/create/location';
import { CreateSafetyTimerReport } from './reports/create/safetyTimer';
import { CreateUserAuthAuditReport } from './reports/create/userAuthAudit';

import { ViewActivityReport } from './reports/view/activity';
import { ViewBillingReport } from './reports/view/billing';
import { ViewDriverReport } from './reports/view/driver';
import { ViewGeofenceReport } from './reports/view/geofence';
import { ViewLocationReport } from './reports/view/location';
import { ViewSafetyTimerReport } from './reports/view/safetyTimer';
import { ViewUserAuthAuditReport } from './reports/view/userAuthAudit';

import { AddUserGroup } from './userGroups/addUserGroup';
import { EditUserGroup } from './userGroups/editUserGroup';
import { EditUserGroupPermissions } from './userGroups/editUserGroupPermissions';
import { UserGroupAssets } from './userGroups/userGroupAssets';
import { UserGroupCallGroups } from './userGroups/userGroupCallGroups';
import { UserGroupUsers } from './userGroups/userGroupUsers';
import { UserGroupsList } from './userGroups/userGroupsList';

import { HistoryMapContainer } from './map/historyMapContainer';
import { LiveMap } from './map/liveMap';
import { Help } from './help';

const MobileMenuIcon = styled(ListItemIcon)({
	minWidth: '38px',
	fontSize: '22px',
});

import './index.scss';
import { baseUrl } from 'src/config';
import { getPrettyName } from 'src/util';
import { EmergencyType } from 'src/../__generated__/globalTypes';

export const App = observer(() => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _brandingService = useInjection<BrandingService>(Service.Branding);
	const _emergencyService = useInjection<EmergencyService>(Service.Emergency);
	const _history = useInjection<HistoryService>(Service.History);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);
	const _webSocketService = useInjection<WebSocketService>(Service.WebSocket);

	const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
	const [mobileSubMenusOpen, setMobileSubMenusOpen] = useState<{ [menu: string]: boolean }>({});

	useEffect(() => {
		_webSocketService.start();

		return () => _webSocketService.resetState() as any;
	}, []);

	const signOut = () => {
		window.location.href = `${baseUrl}/api/v1/auth/sign-out`;
	};

	const signInAsReturn = async () => {
		try {
			await _authService.signInAsReturn();
			navigateTo(`/app`);
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to return.');
		}
	};

	const navigateTo = (url: string) => {
		_history.history.push(url);
	};

	const toggleMobileMenu = () => {
		setMobileMenuOpen(!mobileMenuOpen);
	};

	const toggleMobileSubMenu = (name: string) => {
		const currentlyOpen = !!mobileSubMenusOpen[name];

		setMobileSubMenusOpen({
			...mobileSubMenusOpen,
			[name]: !currentlyOpen,
		});
	};

	const renderEmergencyBar = (): JSX.Element | null => {
		if (_emergencyService.allEmergencies.size === 0)
			return null;

		const emergencies = Array.from(_emergencyService.allEmergencies.values());

		let deviceIoConfigurationName = '';
		if (emergencies[0].deviceIoConfiguration)
			deviceIoConfigurationName = ` (${emergencies[0].deviceIoConfiguration.name})`;

		let emergencyClassName = 'status-bar error';
		let emergencyStatusText = '';

		if (emergencies.length > 1) {
			// The priority order of the emergencies.
			const emergencyPriority = [getPrettyName(EmergencyType.EMERGENCY), getPrettyName(EmergencyType.PRIORITY_ALERT)];

			// Array of emergency types sorted by priority.
			const emergencyTypes = emergencies.map(x => getPrettyName(x.type))
				.sort((a, b) => emergencyPriority.indexOf(a) - emergencyPriority.indexOf(b));

			// Count how many times each emergency type occurs.
			const emergencyTypeCounts: { [emergencyType: string]: number } = {};
			for (const emergencyType of emergencyTypes)
				emergencyTypeCounts[emergencyType] = (emergencyTypeCounts[emergencyType] || 0) + 1;

			// Loop over the unique array of active emergency types.
			const uniqueEmergencyTypes = [...new Set(emergencyTypes)];
			for (const emergencyType of uniqueEmergencyTypes) {
				emergencyStatusText += emergencyTypeCounts[emergencyType] > 1
					? emergencyTypeCounts[emergencyType] + ' ' + emergencyType.split(' ')[0].toLowerCase() + ' alerts & '
					: emergencyTypeCounts[emergencyType] + ' ' + emergencyType.split(' ')[0].toLowerCase() + ' alert & ';
			}

			if (emergencyStatusText.endsWith('& '))
				emergencyStatusText = emergencyStatusText.slice(0, -2);

			emergencyStatusText += emergencies.length > 1 ? 'have been triggered' : 'has been triggered';

			// Change the colour depending on the type of emergencies active.
			if (!emergencyTypeCounts[getPrettyName(EmergencyType.EMERGENCY)] && emergencyTypeCounts[getPrettyName(EmergencyType.PRIORITY_ALERT)])
				emergencyClassName = 'status-bar warning';
		} else if (emergencies.length === 1) {
			switch (emergencies[0].type) {
				case EmergencyType.PRIORITY_ALERT:
					emergencyStatusText = emergencies[0].asset.name + ' has triggered a ' + getPrettyName(emergencies[0].type).split(' ')[0].toLowerCase() + ' alert';
					break;
				default:
					emergencyStatusText = emergencies[0].asset.name + ' has triggered an ' + getPrettyName(emergencies[0].type).split(' ')[0].toLowerCase() + ' alert';
					break;
			}

			if (emergencies[0].type === EmergencyType.PRIORITY_ALERT)
				emergencyClassName = 'status-bar warning';
		}

		return <div key="emergency" className={emergencyClassName}>
			{emergencyStatusText}

			<div className="actions">
				<Button
					text="View"
					variant="contained"
					href="/app/emergencies/list"
				/>

				<Button
					text="Mute"
					variant="contained"
					onClick={() => _emergencyService.dismissAlertEmergencies()}
					disabled={_emergencyService.emergenciesDismissed}
				/>
			</div>
		</div>;
	};

	const renderDesktopMenuItem = (item: AnyItem): JSX.Element | null => {
		if (isUrlItem(item)) {
			return <Button
				key={item.text}
				href={item.url}
				startIcon={item.startIcon}
				text={item.textDesktop || item.text}
				className="white-nav-button"
			/>;
		} else if (isSubItem(item)) {
			return <PopoverMenu
				renderOptions={() => item.children.map(renderDesktopSubMenuItem)}
			>
				<Button
					text={item.textDesktop || item.text}
					startIcon={item.startIcon}
					endIcon={item.endIcon}
					className="white-nav-button"
				/>
			</PopoverMenu>;
		}

		return null;
	};

	const renderDesktopSubMenuItem = (item: AnyItem): JSX.Element | null => {
		if (isUrlItem(item)) {
			return <PopoverMenuItem
				key={item.text}
				icon={item.startIcon}
				text={item.textDesktop || item.text}
				href={item.url}
			/>;
		}

		if (isActionItem(item)) {
			return <PopoverMenuItem
				key={item.text}
				icon={item.startIcon}
				text={item.textDesktop || item.text}
				onClick={()=> item.action()}
			/>;
		}

		return null;
	};

	const renderMobileMenuItem = (item: AnyItem, dontRenderSubItems?: boolean): JSX.Element | JSX.Element[] | null => {
		if (isSubItem(item) && !item.mobileRenderAsSubMenu && !dontRenderSubItems) {
			const items = item.children.map((x) => renderMobileMenuItem(x) as JSX.Element);
			items.unshift(renderMobileMenuItem(item, true) as JSX.Element);
			return items;
		}

		let onClick: (() => void) | undefined;
		let subMenuKey: string | undefined;

		if (isUrlItem(item)) {
			onClick = () => { toggleMobileMenu(); navigateTo(item.url); };
		} else if (isActionItem(item)) {
			onClick = () => { toggleMobileMenu(); item.action(); };
		} else if (isSubItem(item) && item.mobileRenderAsSubMenu) {
			onClick = () => { toggleMobileSubMenu(item.text); };
			subMenuKey = item.text;
		}

		const children = <>
			{onClick && <MobileMenuIcon>{item.startIcon}</MobileMenuIcon>}
			<ListItemText primary={item.text} className={classNames({ 'heading': !onClick })} />

			{subMenuKey && !mobileSubMenusOpen[subMenuKey] && <ExpandMoreIcon />}
			{subMenuKey && mobileSubMenusOpen[subMenuKey] && <ExpandLessIcon />}
		</>;

		const items = [];

		if (onClick)
			items.push(<ListItem key={item.text} button onClick={onClick}>{children}</ListItem>);
		else
			items.push(<ListItem key={item.text}>{children}</ListItem>);

		if (subMenuKey && isSubItem(item)) {
			items.push(<Collapse in={mobileSubMenusOpen[subMenuKey]} unmountOnExit>
				<List disablePadding className="submenu__list">
					{item.children.map((x) => renderMobileMenuItem(x) as JSX.Element)}
				</List>
			</Collapse>);
		}

		return items;
	};

	const permissions = _authService.currentAuth.permissions.general;

	let liveMapMenuIcon: AnyItem | undefined;
	let historyMapMenuIcon: AnyItem | undefined;
	let manageMenu: AnyItem | undefined;

	const manageMenuItems = new Array<AnyItem>();

	if (permissions.manageAddressBooks)
		manageMenuItems.push({ startIcon: <MenuBookIcon fontSize="inherit" />, text: 'Address Books', url: '/app/address-books/list' });

	if (permissions.accessEmergencies)
		manageMenuItems.push({ startIcon: <ErrorOutlineIcon fontSize="inherit" />, text: 'Alerts & Emergencies', url: '/app/emergencies/list' });

	if (permissions.manageAssets)
		manageMenuItems.push({ startIcon: <CommuteIcon fontSize="inherit" />, text: 'Assets', url: '/app/assets/list' });

	if (permissions.manageBeacons)
		manageMenuItems.push({ startIcon: <WifiTetheringIcon fontSize="inherit" />, text: 'Beacons', url: '/app/bluetooth-beacons/list' });

	if (permissions.accessCalls)
		manageMenuItems.push({ startIcon: <CallIcon fontSize="inherit" />, text: 'Call Recordings', url: '/app/calls' });

	if (_authService.currentAuth.user.identity.type === C.IdentityType.Dealer && permissions.manageClients)
		manageMenuItems.push({ startIcon: <BusinessIcon fontSize="inherit" />, text: 'Clients', url: '/app/clients/list' });

	if (permissions.manageDealers)
		manageMenuItems.push({ startIcon: <WorkIcon fontSize="inherit" />, text: 'Dealers', url: '/app/dealers/list' });

	if (permissions.manageDevices)
		manageMenuItems.push({ startIcon: <RouterIcon fontSize="inherit" />, text: 'Devices', url: '/app/devices/list' });

	if (permissions.manageFloorPlans)
		manageMenuItems.push({ startIcon: <LayersIcon fontSize="inherit" />, text: 'Floor Plans', url: '/app/floorplans/list' });

	if (permissions.manageGateways)
		manageMenuItems.push({ startIcon: <CloudUploadIcon fontSize="inherit" />, text: 'Gateways', url: '/app/gateways/list' });

	if (permissions.manageGeofences)
		manageMenuItems.push({ startIcon: <FormatShapesIcon fontSize="inherit" />, text: 'Geofences', url: '/app/geofences/list' });

	if (permissions.linkAssets)
		manageMenuItems.push({ startIcon: <SmartphoneIcon fontSize="inherit" />, text: 'Linked Devices', url: '/app/linked-devices/list'});

	if (permissions.manageSites)
		manageMenuItems.push({ startIcon: <BubbleChartIcon fontSize="inherit" />, text: 'Sites', url: '/app/sites/list' });

	if (permissions.manageUsers)
		manageMenuItems.push({ startIcon: <PeopleIcon fontSize="inherit" />, text: 'Users', url: '/app/users/list' });

	if (permissions.manageAssets)
		manageMenuItems.push({ startIcon: <DriveEtaIcon fontSize="inherit" />, text: 'Vehicle Information', url: '/app/vehicle-information/list' });

	if (permissions.viewAssetLocations) {
		historyMapMenuIcon = { text: 'History', startIcon: <HistoryIcon fontSize="inherit" />, url: '/app/map/history' };
		liveMapMenuIcon = { text: 'Map', startIcon: <PublicIcon fontSize="inherit" />, url: '/app/map'};
	}

	if (manageMenuItems.length > 0)
		manageMenu = { text: 'Manage', endIcon: <ArrowDropDownIcon />, startIcon: <SettingsIcon fontSize="inherit" />, children: manageMenuItems };

	const accountMenuChildren: AnyItem[] = [{startIcon: <AccountBoxIcon fontSize="inherit" />, text: 'Account settings', url: '/app/account'}];
	let accountMenuIcon = <PersonIcon fontSize="inherit" />;
	const signInAsActor = _authService.currentAuth.signInAsActor;
	if (signInAsActor) {
		accountMenuIcon = <PeopleAltIcon fontSize="inherit" />;
		const signInAsReturnMenuItem: ActionItem = { startIcon: <UndoIcon fontSize="inherit" />, text: `Return to your own account (${signInAsActor.name})`, action: signInAsReturn };
		accountMenuChildren.push(signInAsReturnMenuItem);
	}
	accountMenuChildren.push({ startIcon: <ExitToAppIcon fontSize="inherit" />, text: 'Sign out', action: signOut });

	const accountMenu: AnyItem = { text: 'Account', startIcon: accountMenuIcon, endIcon: <ArrowDropDownIcon />, textDesktop: _authService.currentAuth.user.name, children: accountMenuChildren };

	const helpMenuItem: AnyItem = { startIcon: <HelpIcon fontSize="inherit" />, text: 'Help', url: '/app/help' };

	const reportsMenuItem: AnyItem = { startIcon: <AssessmentIcon fontSize="inherit" />, text: 'Reports', url: '/app/reports' };

	return <>
		<header>
			{renderEmergencyBar()}

			<nav className="navbar">
				<div className="navbar-content fixed-width">
					<Link to="/app" className="navbar-branding">
						<img src={_brandingService.getBranding().navbarImage}  />
					</Link>

					<div className="navbar-menu">
						<div className="navbar-menu-big">
							{liveMapMenuIcon && renderDesktopMenuItem(liveMapMenuIcon)}
							{historyMapMenuIcon && renderDesktopMenuItem(historyMapMenuIcon)}
							{_authService.currentAuth.permissions.general.canUseReporting && reportsMenuItem && renderDesktopMenuItem(reportsMenuItem)}
							{manageMenu && renderDesktopMenuItem(manageMenu)}
							<span className="navbar-divider"></span>
							{renderDesktopMenuItem(accountMenu)}
							<span className="navbar-divider"></span>
							{renderDesktopMenuItem(helpMenuItem)}
						</div>

						<div className="navbar-menu-small">
							<Button
								text={<MenuIcon />}
								onClick={toggleMobileMenu}
								className="white-nav-button"
							/>

							<Drawer
								anchor="right"
								open={mobileMenuOpen}
								onClose={toggleMobileMenu}
							>
								<div className="mobile-menu">
									<div className="header">
										{_authService.currentAuth.user.name}
									</div>

									{(liveMapMenuIcon || historyMapMenuIcon) && <>
										<List>
											{liveMapMenuIcon && renderMobileMenuItem(liveMapMenuIcon)}
											{historyMapMenuIcon && renderMobileMenuItem(historyMapMenuIcon)}
										</List>

										<Divider />
									</>}

									{_authService.currentAuth.permissions.general.canUseReporting &&  reportsMenuItem && <>
										{renderMobileMenuItem(reportsMenuItem)}

										<Divider />
									</>}

									{manageMenu && <>
										<List>
											{renderMobileMenuItem(manageMenu)}
										</List>

										<Divider />
									</>}

									<List>
										{renderMobileMenuItem(accountMenu)}
									</List>

									<Divider />
									{renderMobileMenuItem(helpMenuItem)}
								</div>
							</Drawer>
						</div>
					</div>
				</div>
			</nav>
		</header>

		<Switch key="content">
			<Route exact path="/app/account" component={Account} />
			<Route exact path="/app/account/change-password" component={ChangePassword} />

			<Route exact path="/app/address-books/list" component={AddressBooksList} />
			<Route exact path="/app/address-books/:id/edit" component={EditAddressBooks} />
			<Route exact path="/app/address-books/add" component={ManageAddressBooks} />

			<Route exact path="/app/asset-service-reminder-types/list" component={AssetServiceRemindersTypesList} />
			<Route exact path="/app/asset-service-reminder-types/:id/edit" component={EditAssetServiceReminderType} />
			<Route exact path="/app/asset-service-reminder-types/add" component={ManageAssetServiceReminderType} />

			<Route exact path="/app/assets/:id/service-reminders/history" component={AssetServiceRemindersHistoryList} />
			<Route exact path="/app/asset-service-reminders/:id/list" component={AssetServiceRemindersList} />
			<Route exact path="/app/asset-service-reminders/:id/edit" component={EditAssetServiceReminder} />
			<Route exact path="/app/asset-service-reminder-types/:id/reminders/add" component={AddAssetServiceReminderForReminderType} />
			<Route exact path="/app/assets/:id/service-reminders/add" component={AddAssetServiceReminderForAsset} />

			<Route exact path="/app/assets/list" component={AssetsList} />
			<Route exact path="/app/assets/:id/edit" component={EditAsset} />
			<Route exact path="/app/assets/add" component={AddAsset} />
			<Route exact path="/app/assets/add-with-device" component={AddAssetWithDevice} />
			<Route exact path="/app/assets/import-from-file" component={ImportAssetsFromFile} />

			<Route exact path="/app/assets/:id/io-configurations/list" component={IoConfigurationsList} />

			<Route exact path="/app/bluetooth-beacons/list" component={BeaconsList} />
			<Route exact path="/app/bluetooth-beacons/add" component={AddBeacon} />
			<Route exact path="/app/bluetooth-beacons/:id/edit" component={EditBeacon} />
			<Route exact path="/app/bluetooth-beacons/:id/place" component={PlaceBeacon} />

			<Route exact path="/app/ble-beacon-sets/add" component={AddBleBeaconSet} />
			<Route exact path="/app/ble-beacon-set/:id/edit" component={EditBleBeaconSet} />
			<Route exact path="/app/ble-beacon-sets/list" component={BleBeaconSetsList} />
			<Route exact path="/app/ble-beacon-set/:id/manage" component={ManageBleBeaconSet} />

			<Route exact path="/app/asset-groups/list" component={AssetGroupsList} />
			<Route exact path="/app/asset-groups/add" component={AddAssetGroup} />
			<Route exact path="/app/asset-groups/:id/edit" component={EditAssetGroup} />
			<Route exact path="/app/asset-groups/:id/manage" component={ManageAssetGroup} />

			<Route exact path="/app/asset-types/list" component={AssetTypesList} />
			<Route exact path="/app/asset-types/add" component={AddAssetType} />
			<Route exact path="/app/asset-types/:id/edit" component={EditAssetType} />

			<Route exact path="/app/device-configurations/list" component={DeviceConfigurationsList} />
			<Route exact path="/app/device-configurations/add" component={AddDeviceConfiguration} />

			<Route exact path="/app/calls" component={CallsIndex} />

			<Route exact path="/app/clients/list" component={ClientsList} />
			<Route exact path="/app/clients/add" component={AddClient} />
			<Route exact path="/app/clients/:id/overview" component={ClientOverview} />
			<Route exact path="/app/clients/:id/edit" component={EditClient} />

			<Route exact path="/app/dealers/list" component={DealersList} />
			<Route exact path="/app/dealers/add" component={AddDealer} />
			<Route exact path="/app/dealers/:id/edit" component={EditDealer} />

			<Route exact path="/app/devices/list" component={DevicesList} />
			<Route exact path="/app/devices/add" component={AddDevice} />
			<Route exact path="/app/devices/:id/edit" component={EditDevice} />

			<Route exact path="/app/emergencies/list" component={EmergencyList} />
			<Route exact path="/app/emergencies/history/list" component={EmergencyHistoryList} />
			<Route exact path="/app/emergencies/:id/details" component={EmergencyDetails} />

			<Route exact path="/app/enablefleet/list" component={EnableFleetConfigurationsList} />
			<Route exact path="/app/enablefleet/add" component={AddEnableFleetConfiguration} />
			<Route exact path="/app/enablefleet/:id/edit" component={EditEnableFleetConfiguration} />
			<Route exact path="/app/enablefleet/:id/fields" component={EditEnableFleetConfigurationFields} />
			<Route exact path="/app/enablefleet/:id/permissions/:siteId" component={ManageEnableFleetPermissions} />
			<Route exact path="/app/enablefleet/assets/:id/configure" component={ConfigureEnableFleetAsset} />

			<Route exact path="/app/floorplans/list" component={FloorPlanCollectionsList} />
			<Route exact path="/app/floorplans/:collectionId/list" component={FloorPlansList} />
			<Route exact path="/app/floorplans/:floorPlanGroupId/add" component={AddFloorPlan} />
			<Route exact path="/app/floorplans/:id/edit" component={EditFloorPlan} />

			<Route exact path="/app/gateways/list" component={GatewayList} />
			<Route exact path="/app/gateways/add" component={AddGateway} />
			<Route exact path="/app/gateways/:id/edit" component={EditGateway} />
			<Route exact path="/app/gateways/:id/overview" component={GatewayOverview} />
			<Route exact path="/app/gateways/:id/status" component={GatewayStatus} />

			<Route exact path="/app/geofences/list" component={GeofenceList} />
			<Route exact path="/app/geofences/types/list" component={GeofenceTypeList} />
			<Route exact path="/app/geofences/types/add" component={AddGeofenceType} />
			<Route exact path="/app/geofences/types/:id/edit" component={EditGeofenceType} />

			<Route exact path="/app/linked-devices/list" component={LinkedDeviceList} />

			<Route exact path="/app/users/list" component={UsersList} />
			<Route exact path="/app/users/add" component={AddUser} />
			<Route exact path="/app/users/:id/edit" component={EditUser} />
			<Route exact path="/app/users/:id/groups" component={ManageUserGroups} />
			<Route exact path="/app/users/:id/settings/gateway-alerts" component={UserGatewayAlertSettings} />
			<Route exact path="/app/users/:id/settings/alerts" component={EditUserAlertSettings} />

			<Route exact path="/app/reports" component={ReportsIndex} />
			<Route exact path="/app/reports/create" component={CreateReport} />
			<Route exact path="/app/reports/create/activity" component={CreateActivityReport} />
			<Route exact path="/app/reports/create/asset-events-export" component={CreateAssetEventsExport} />
			<Route exact path="/app/reports/create/asset-log-history" component={CreateAssetLogHistoryReport} />
			<Route exact path="/app/reports/create/billing" component={CreateBillingReport} />
			<Route exact path="/app/reports/create/daily" component={CreateDailyReport} />
			<Route exact path="/app/reports/create/driver" component={CreateDriverReport} />
			<Route exact path="/app/reports/create/geofence" component={CreateGeofenceReport} />
			<Route exact path="/app/reports/create/location" component={CreateLocationReport} />
			<Route exact path="/app/reports/create/safety-timer" component={CreateSafetyTimerReport} />
			<Route exact path="/app/reports/create/user-auth-audit" component={CreateUserAuthAuditReport} />

			<Route exact path="/app/reports/view/activity/:id" component={ViewActivityReport} />
			<Route exact path="/app/reports/view/billing/:id" component={ViewBillingReport} />
			<Route exact path="/app/reports/view/driver/:id" component={ViewDriverReport} />
			<Route exact path="/app/reports/view/geofence/:id" component={ViewGeofenceReport} />
			<Route exact path="/app/reports/view/location/:id" component={ViewLocationReport} />
			<Route exact path="/app/reports/view/safety-timer/:id" component={ViewSafetyTimerReport} />
			<Route exact path="/app/reports/view/user-auth-audit/:id" component={ViewUserAuthAuditReport} />

			<Route exact path="/app/reports/scheduled" component={ScheduledReportsList} />
			<Route exact path="/app/reports/scheduled/create" component={CreateScheduledReport} />
			<Route exact path="/app/reports/scheduled/:id/edit" component={EditScheduledReport} />

			<Route exact path="/app/user-groups/list" component={UserGroupsList} />
			<Route exact path="/app/user-groups/add" component={AddUserGroup} />
			<Route exact path="/app/user-groups/:id/edit" component={EditUserGroup} />
			<Route exact path="/app/user-groups/:id/permissions" component={EditUserGroupPermissions} />
			<Route exact path="/app/user-groups/:id/assets" component={UserGroupAssets} />
			<Route exact path="/app/user-groups/:id/call-groups" component={UserGroupCallGroups} />
			<Route exact path="/app/user-groups/:id/users" component={UserGroupUsers} />

			<Route exact path="/app/vehicle-information/list" component={VehicleInformationList} />
			<Route exact path="/app/vehicle-information/:id" component={VehicleInformation} />

			<Route exact path="/app/sites/list" component={SiteList} />
			<Route exact path="/app/sites/add" component={AddEditSite} />
			<Route exact path="/app/sites/:id/edit" component={AddEditSite} />
			<Route exact path="/app/sites/:id/call-groups" component={CallGroupsList} />

			<Route exact path="/app/map" component={LiveMap} />
			<Route exact path="/app/map/history" component={HistoryMapContainer} />

			<Route exact path="/app/help" component={Help} />

			<Route exact path="/app" component={Welcome} />
			<Route path="*" component={PageNotFound} />
		</Switch>
	</>;
});
